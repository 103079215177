
.btn {
  border: none;
  padding: 11px 32px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  &.schedule {
    background: url("/static/img/icon-calendar2.svg") no-repeat;
    background-position: left 20px center;
  }

  &.big {
    padding: 0.7rem 1.4rem;
    font-size: 1.1rem;
  }

  &.btn-sm {
    padding: 0.25rem 0.5rem;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba($aspargus, 0.25)
  }

  &.delete {
    width: 20px;
    height: 20px;
    content: "";
    background-image: url("/static/img/icon-delete.svg");
    background-size: 14px auto;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 5px;
    transition: ease 0.2s all;
    display: flex;
    align-self: center;

  }
}

.btn-primary {
  color: $snowdrift;
  background-color: $aspargus;

  transition: ease 0.2s all;

  &.wide {
    padding-left: 50px;
    padding-right: 50px;
  }

  &:hover {
    background-color: darken($aspargus, 10) !important;
    border-color: darken($aspargus, 5) !important;
  }

  &:active {
    background-color: darken($aspargus, 20) !important;
    border-color: darken($aspargus, 15) !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem lighten($aspargus, 5) !important;
  }

  &.pay {
    margin-top: 20px;
    background-color: $aspargus;
  }

  @media screen and (max-width: 1200px) and(min-width: 992px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.disabled {
    background: darken($desertstorm, 5);
    color: lighten($desertstorm, 5);
    border-color: lighten($desertstorm, 5);

    &:hover {
      background: darken($desertstorm, 5) !important;
    }
  }
}

.btn-secondary {
  color: $aspargus;
  background-color: rgba($aspargus, 0.1);
  border: 1px solid transparent;
  transition: ease 0.2s all;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;


  &:hover {
    background-color: darken($aspargus, 10);
    border: $aspargus 1px solid;
  }

  &.wide {
    padding-left: 50px;
    padding-right: 50px;;
  }

  &.pay {
    margin-top: 20px;
    background-color: $aspargus;
  }
}

.btn-link {
  color: lighten($heavymetal, 40);
  background-color: transparent;
  transition: ease 0.2s all;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $heavymetal;
  }

  &:focus, &:active {
    box-shadow: none;
  }

}


.btn-text {
  background: none !important;
  border: none;
  color: lighten($heavymetal, 10);
  font-weight: 100;
  transition: ease 0.2s all;
  width: none;
  box-shadow: none !important;

  &:hover {
    color: lighten($aspargus, 10);
    background: none !important;
    border: none;

    text-decoration: underline;
  }

  &.danger {
    &:hover {
      color: $shiraz;
    }
  }

  &.pay {
    margin-top: 20px;
    background-color: $aspargus;
  }

  &.secondary {
    color: lighten($snowdrift, 9) !important;

  }

  &.small {
    font-size: 0.8em;
  }
}


.btn-edit {
  color: $calypso;
  font-weight: 100;
  font-size: 0.8em;
}


.btn-three-dots {
  padding: 20px 10px;
  border: none;
  outline: none;
  content: " ";
  width: 20px;
  height: 20px;
  left: 8px;
  top: 5px;
  background: url('/static/img/icon-three-dots.svg') no-repeat center center;
  background-size: 24px auto;
  width: 30px;

  &:hover, &:focus, &:active {
    background: url('/static/img/icon-three-dots.svg') no-repeat center center;
    border: none;
    box-shadow: none !important;
    background-color: transparent !important;

  }
}

.dropdown-menu {
  border: none;
  box-shadow: 0px 0px 21px -4px rgba(0, 0, 0, 0.07);

  button {
    &.dropdown-item {
      background: none;

      a {
        font-weight: 600;
        color: $heavymetal;

      }

      &:hover {
        a {
          color: $aspargus;
        }
      }
    }
  }
}

.btn-top-right {
  position: absolute;
  right: 40px;
  top: 54px;
  font-size: 14px;

  a {
    color: $aspargus;
  }
}
