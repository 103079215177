.quiz-slide {
  padding: 40px;

  @media (max-width: 992px) {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  img {
    max-width: 100%;
  }

  &.center {
    margin: 0 auto;

    button {
      margin: 10px 0;
      align-self: center;
    }

    h4 {
      text-align: center;
    }

    @media (max-width: 992px) {
      text-align: center;
    }
  }

  .row {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex-direction: column;
      min-height: 200px;
      display: block;
    }
  }

  h3 {
    font-size: 24px;
    @media (max-width: 992px) {
      font-size: 16px;
    }
  }

  h5 {
    font-size: 14px;;
    margin: 0;

    @media (max-width: 992px) {
      font-size: 12px;
      color: rgba($heavymetal, 0.6);
      margin-bottom: 4px;
    }
  }

  p {
    font-size: 16px;
    line-height: 150%;
    color: rgba($heavymetal, 0.6);
    max-width: 700px;
  }

  strong {
    color: $heavymetal;
  }

  .actions {
    margin-top: 20px;

    button {
      margin-left: 0;
    }
  }

  .quiz-results {
    padding: 40px 40px 0 40px;
    max-width: 760px;
    margin: 30px 0 0 0;

    p {
      font-size: 16px;
      color: rgba($heavymetal, 0.6);
    }

    button {
      margin-right: 20px;
      font-weight: 700;
    }

    .scoreHeading {
      text-align: left;
      @media (max-width: 992px) {
        text-align: center;
      }
    }
  }

  .btn-text {
    text-transform: uppercase;
    padding: 0.5rem;
    font-size: 13px;
    letter-spacing: 0.04em;
    font-weight: 700;
    color: rgba($heavymetal, 0.6);
    cursor: pointer;
    text-decoration: none;;

    &:hover {
      color: rgba($heavymetal, 0.8);
    }
  }
}

.assessment-review {
  @media (max-width: 992px) {
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 12px;
      color: $heavymetal;
    }
  }
}

.quiz-results-table {
  max-width: 700px;

  @media (max-width: 992px) {
    max-width: 90%;
  }
}

.progress-semicircle {
  margin: 10px auto;
  position: relative;

  &.small {
    width: 66px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: -4px;
  }

  &.with-stars {
    padding-top: 30px;

    &.green {
      &:after {
        background: url('/static/img/stars-green.svg') no-repeat top center;
      }
    }

    &.yellow {
      &:after {
        background: url('/static/img/stars-yellow.svg') no-repeat top center;
      }
    }

    &.red {
      &:after {
        background: url('/static/img/stars-red.svg') no-repeat top center;
      }
    }

    &:after {
      position: absolute;
      content: "";
      background-size: 100% auto;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: -0px;
      right: 0;
    }
  }

  .text {
    text-align: center;

    .score {
      font-size: 40px;
      font-weight: 700;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top: 90px;
      }
    }

    .description {
      color: #8E8E8E;
      font-weight: 100;;
      font-size: 14px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top: -9px;
      }
    }

    &.small {
      margin-top: -4px;
      font-weight: 600;

      .score {
        font-size: 15px;
        font-weight: 800;;
      }
    }
  }
}


.assessment {
  .assessment-header {
    background: white;
    height: 70px;
    box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.08);
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
    max-width: 1560px;
    width: 100%;
  }

  .assessment-body {
    display: flex;
    overflow: hidden;
    max-width: 1560px;
    width: 100%;
    height: 100%;
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 40px;
    @media (max-width: 992px) {
      padding: 0 10px;
    }
  }


  .assessment-question {
    width: 100%;
    flex-direction: column;
    display: flex;
    max-width: 80%;
    margin: 0 auto;

    @media (max-width: 992px) {
      padding-bottom: 20px;
      max-width: 100%;
      margin: 0;
    }

    .password-tester-formcontrol {
      width: 120px;
      @media (max-width: 992px) {
        align-self: center;
      }
    }

    /* reset for MU margins */
    .MuiFormControlLabel-root {
      margin-left: unset;
      margin-right: unset;
    }

    .MuiTypography-root {
      margin-left: 10px;
      @media (max-width: 992px) {
        font-size: 14px;
      }
    }

    h5 {
      font-weight: 700;
      padding: 0;
      margin: 0;
      color: #878887;

      @media (max-width: 992px) {
        font-size: 14px;
        margin-bottom: 8px;
      }

    }

    h3 {

      font-weight: 700;
      padding: 0;
      margin: 0;
      font-size: 20px;

      @media (max-width: 992px) {
        font-size: 16px;
        line-height: inherit;
      }

    }

    p {
      font-size: 16px;
      color: $heavymetal;
      padding: 10px 0 10px 0;
    }

    .image-fullwidth {
      padding-bottom: 20px;

      img {
        width: 100%;
      }
    }

    &.seperate-buttons {
      .MuiToggleButtonGroup-root {
        @media (max-width: 992px) {
          display: flex;
          justify-content: center;
        }
        position: relative;

        .MuiButtonBase-root {
          margin-right: 10px;
          font-weight: 600;
          font-size: 15px;

          @media (max-width: 992px) {
            max-width: 45%;
            font-size: 14px;
            font-weight: 700;
            margin: 0 10px;
          }

          &.MuiToggleButtonGroup-grouped {
            margin-right: 10px;
            border-radius: 4px;

          }
        }
      }
    }

    // with answers
    .score-0 {
      .Mui-selected {
        background-color: $score-0;
        border-top: 0;
        border-bottom: 0;
      }
    }

    .score-25 {
      .Mui-selected {
        background-color: $score-25;
        border-top: 0;
        border-bottom: 0;
      }
    }

    .score-50 {
      .Mui-selected {
        background-color: $score-50;
        border-top: 0;
        border-bottom: 0;
      }
    }

    .score-75 {
      .Mui-selected {
        background-color: $score-75;
        border-top: 0;
        border-bottom: 0;
      }
    }

    .score-100 {
      .Mui-selected {
        background-color: $score-100;
        border-top: 0;
        border-bottom: 0;
      }
    }


  }


  .assessment-question-checkbox {
    max-width: 600px;
    margin: 20px auto;
  }

  .assessment-buttons {
    display: flex;
    flex-direction: row;;
    padding: 10px 20px;
    width: 100%;
    max-width: 800px;
    justify-content: space-around;
    font-size: 14px;


  }

  .assessment-close {
    background: white;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 20px;
    top: 16px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .assessment-block {
    padding: 12px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(29, 30, 29, 0.06);
    border-radius: 4px;
  }


}

.full-screen {
  height: 100%;
}

.image-zoomed {
  position: fixed;
  background: rgba($heavymetal, 0.9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    max-width: 95%;;
  }

  &:after {
    content: "";
    cursor: pointer;
    position: absolute;
    background: black url('/static/img/icon-close.svg') no-repeat center center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 10px;
    left: 10px;
  }
}

.assessment-presentation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;

  @media (max-width: 992px) {
    padding-top: 10px;
  }

  .password {
    background: rgba(29, 30, 29, 0.03);
    padding: 8px 20px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-self: flex-start;
    margin: 3px 0 10px 0;
  }

  .image {
    text-align: center;
    @media (max-width: 992px) {
      // width: 40%;
      align-self: center;
      padding-bottom: 20px;
      img {
        width: 100%;
        align-self: center;
      }
    }
  }

  .email-gmail {
    margin-top: 20px;
    width: 560px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08);
    border-radius: 8px;
    padding: 20px 20px 40px 20px;
    margin-bottom: 40px;
    max-width: 100%;
    position: relative;
    @media (max-width: 992px) {
      width: 100%;
    }

    .email-header {
      display: flex;
    }

    .profile-img {
      border-radius: 50%;
      color: white;
      font-size: 17px;
      font-weight: 700;
      padding: 8px;
      margin-right: 5px;
      display: flex;
      height: 40px;
      width: 40px;
      margin-top: -5px;

      @media (max-width: 560px) {
        margin-right: 10px;
      }
    }


    .details-window {
      &.tooltip-from {
        @media (max-width: 992px) {
          margin-top: 20px;
          visibility: visible !important;
        }
      }
    }

    .details-tooltip {
      position: relative;
      display: inline-block;
      padding-left: 4px;
    }

    .details-tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;

      /* Position the tooltip */
      position: absolute;
      z-index: 1;

      top: 100%;
      left: 50%;
      margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
    }


    .email-top-line {
      display: flex;
      font-size: 14px;
      font-weight: 600;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: -5px;
      margin-left: 7px;
      width: 100%;
      flex-direction: column;
      @media (max-width: 992px) {
        margin-left: 0;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
      }

      .details-header {
        justify-content: space-between;
        align-items: center;
        display: flex;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        padding-left: 4px;
      }

      .email-time {
        color: rgba($heavymetal, 0.8);
        font-size: 12px;
        font-weight: 400;
        justify-self: flex-end;
        margin-left: 10px;
      }

      .mobile-email-time {
        margin-left: 5px;
        font-size: 12px;
        font-weight: 400;
        color: rgba($heavymetal, 0.6);
        margin-left: 10px;
      }

      .email-icons {
        margin-left: auto;
      }

      .right {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;

        img {
          padding: 0;
        }
      }
    }

    .email-hover-text {
      font-size: 12px;
      padding: 0px 2px;
      cursor: pointer;
      transition: ease 0.2s all;
      font-weight: normal;

      .to-field {
        @media (max-width: 992px) {
          margin-left: 50px;
        }
      }

      @media (max-width: 992px) {
        position: static;
      }

      &:hover {
        .hover-details {
          opacity: 1;
        }
      }

      .hover-details {
        top: 57px;
        left: 70px;
        opacity: 0;
        padding: 4px 8px;
        border-radius: 4px;;
        background: white;
        white-space: nowrap;
        position: absolute;
        box-shadow: 0px 0px 21px -4px rgba(0, 0, 0, 0.17);
        pointer-events: none;
      }

    }

    /*.email-text {
      padding-top: 30px;

      .link {
        cursor: pointer;

        &.text {
          color: #2457C5;
          text-decoration: underline;
        }
      }

      p {
        max-width: 100%;;
        font-family: arial;
        font-size: 12px !important;
        line-height: 16px;
        padding: 4px 4px 4px 0;
      }
    }*/

    .email-footer {
      .attachment {
        width: 180px;
      }

      img {
        cursor: pointer;
      }
    }


    .details-tooltiptext {
      background: white;
      filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
      border: 1px solid #CCCCCC;
      border-radius: 2px;
      color: black;
      width: 360px;
      opacity: 0;


    }

    .tooltip-from {
      display: flex;
      // position: static;
      width: 80%;
      border: 1px solid #CCCCCC;
      padding: 16px;
      background-color: white;
      box-shadow: 0px 0px 21px -4px rgba(0, 0, 0, 0.17);
      position: absolute;
      font-size: 12px;
      cursor: pointer;
      transition: ease 0.2s all;
      left: 70px;
      top: 36px;
      padding: 12px;

      @media (max-width: 992px) {
        box-shadow: none;
        border-radius: 6px;
        width: 100%;
        display: block;
        // background-color: pink !important;
        margin-top: 20px;
        position: static;
        margin-left: -50px;
        padding-bottom: 17px;
        box-sizing: content-box;
        overflow-wrap: break-word;
      }

      .left {
        @media (max-width: 922px) {
          // width: 10%!important;
          // padding-left: 0!important;
          // padding-right: 0!important;
        }
        padding-left: 20px;
        color: #999999;
        text-align: right;;
      }

      .right {
        @media (max-width: 922px) {
          // width: 80%!important;
        }
        padding-left: 20px;
        // padding-left: 0px;
        text-align: left;;
      }
    }

    .email-attachement-hover {
      position: absolute;
      left: 0;
      bottom: 0;
      font-size: 12px;
      background: #DFE1E6;
      padding: 2px 6px;
      z-index: 1000;
    }

    .email-attention {
      position: absolute;
      bottom: -60px;
      background: #E8EAE9;
      padding: 10px 20px;
      font-size: 12px;
      border-radius: 10px;
      transition: ease 0.2s all;


      @media (max-width: 992px) {
        bottom: -50px;
      }
    }

    .link-tooltip {
      position: absolute;
      border: 1px solid #CCCCCC;
      padding: 6px;
      border-radius: 2px;;
      background-color: white;
      box-shadow: 0px 0px 21px -4px rgba(0, 0, 0, 0.17);
      z-index: 1000;
    }
  }

  .question {
    max-width: 560px;
    width: 100%;
    flex-direction: column;
    display: flex;
    margin: 0 auto;

    @media (max-width: 992px) {
      max-width: initial;
      padding: 20px 10px !important;
    }

    h3 {
      margin: 0 0 10px 0;
      line-height: 150%;

      @media (max-width: 992px) {
        font-size: 20px;
      }

    }

    h5 {
      margin: 0 0 10px 0;
      line-height: 150%;
      font-size: 16px;
    }

    p {
      font-size: 16px;
      color: rgba($heavymetal, 0.8);
      white-space: pre-line;
    }

    normal {
      font-style: normal;
      font-weight: 400;
      color: rgba(52, 53, 52, 0.8)

    }

    .disclaimer {
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 32px;

      .disclaimer-icon {
        height: 22px;
        margin-right: 5px;
      }
    }

    .image {
      margin-top: 30px;
    }
  }

  h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
  }

  .chat-message {
    display: flex;
    @media (max-width: 992px) {
      align-self: center;
    }

    .img {
      display: flex;
      align-items: center;
      padding: 0 6px 0 0;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .message {
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      padding: 12px;
      font-size: 14px;;
      max-width: 380px;

      .top {
        display: flex;
        flex-direction: row;

        .name {
          font-weight: 700;;
        }

        .time {
          font-weight: 100;
          padding: 0 5px;
          font-size: 12px;
          line-height: 20px;;
        }
      }

      .text {
        line-height: 1.3em;
        color: black;
        padding-top: 4px;
      }
    }
  }

  .password-tester {
    display: flex;
    flex-direction: revert;
    width: 100%;
    position: relative;
    margin-top: 20px;

    .MuiInputBase-root {
      &:after {
        content: "";
        background: url('/static/img/assessment/icon-eye.svg') no-repeat center center;
        padding: 12px 8px;
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 1;
        text-align: center;
        width: 10%;
        height: 100%;
        pointer-events: none;
      }
    }

    &.visible {
      .MuiInputBase-root {
        &:after {
          background: url('/static/img/assessment/icon-eye-closed.svg') no-repeat center center;
        }
      }
    }

    .password-input {
      background: #FCFCFC;
      font-size: 14px;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }

    }

    // show password checkbox hack
    .show-password {
      position: absolute;
      width: 54px;
      height: 55px;
      right: 123px;

      @media (max-width: 992px) {
        right: 0px;
      }

    }


    .MuiFormControl-root {
      display: flex;
      flex-direction: row;
      width: 100%;

      @media (max-width: 992px) {
        flex-direction: column;
      }

      .MuiInputBase-root {
        width: 100%;
      }

      .MuiButtonBase-root {
        background: #20A26B;
        color: white;
        margin-left: 20px;

        @media (max-width: 992px) {
          width: 100%;
          margin: 0;
        }

        &.button-disabled {
          background: rgba(#20A26B, 0.9);
          text-indent: -9999px;
        }
      }
    }

    .MuiButtonBase-root {
      width: 20%;
    }
  }

  .password-tester-results {
    font-size: 12px;
    line-height: 20px;
    padding: 20px 0;

    h5 {
      span {
        font-weight: normal;
      }
    }

    .meter {
      height: 8px; /* Can be anything */
      position: relative;
      background: #F6F6F6;
      border-radius: 4px;
      box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
      margin-bottom: 10px;
      display: relative;
      overflow: hidden;
      margin: 10px 0 20px 0;

      span {
        display: block;
        height: 100%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        top: 0;
        left: 0;
        position: absolute;
        overflow: hidden;

      }
    }

    ul {
      padding-inline-start: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        padding-right: 30px;
        list-style: none;
        width: 33%;
      }
    }

    .action {
      font-size: 12px;
      line-height: 24px;
      font-weight: 700;

      &.incorrect {
        color: #EB5757;
        padding-left: 20px;
        background: url('/static/img/assessment/icon-incorrect-simple.svg') no-repeat left center;
      }

      &.correct {
        color: #20A26B;
        padding-left: 20px;
        background: url('/static/img/assessment/icon-correct-simple.svg') no-repeat left center;
      }
    }
  }
}

@keyframes meter-progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.assessment-block-type-2 {
  background: #F9FAF9;
  padding-top: 10px;

  @media (max-width: 992px) {
    width: 100%;
    align-self: center;
    padding: 20px;
  }
}

.assessment-carousel-progress {
  display: block;

  .step-follow  {
    background: url('/static/img/icon-step-following.svg') no-repeat center center;
    width: 10px;
    height: 10px;
    background: blue;
  }
}

.assessment-carousel {
  display: flex;
  max-width: 80%;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  padding: 40px 0 20px 0;
  @media (max-width: 992px) {
    padding: 20px 0;
    max-width: 90%;
  }
  @media (max-width: 599px) {
    padding: 10px 0px;
    max-width: 96%;

  }
}

.assessment-padding {
  padding: 100px 40px 0 40px;
  @media (max-width: 992px) {
    padding-top: 0px;
  }
}

.assessment-carousel-buttons {
  button {
    background: none;
    //border: 1px solid #1D1E1D;
    border: 1px solid #e3e3e3;
    border-radius: 50%;
    box-shadow: none;
    margin-left: 8px;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &.Mui-disabled {
      opacity: 0.2;
      background: none;
    }
  }
}

.progress-semicircle {
  width: 200px;
  background: url();

  svg {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      transform: rotate(-105deg);
      margin-top: -10px;
    }
  }
}

// fix for seperate buttons
.assessment-question {
  .image {
    margin: 0 auto;

    @media (max-width: 992px) {
      margin: 0;
      img {
        width: 100%;
      }
    }

    img {
      max-width: 100%;
    }

    &.clickable {
      cursor: pointer;
    }

    &.fullwidth {
      margin: 20px 0;

      img {
        width: 100%;
      }
    }
  }
}

.post_linkedin, .post_facebook {
  background: white;
  border: 1px solid #F3F4F4;
  max-width: 468px;
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  .post_header {
    display: flex;
    flex-direction: row;
    padding: 20px 20px 10px 20px;

    .right {
      padding-left: 10px;

      h3 {
        margin: 0;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        line-height: 20px;
        font-size: 14px;
        font-weight: bold;

        span {
          font-weight: 400;
          margin-left: 5px;
          color: #999999;
        }
      }

      p {
        margin: 0;
        padding: 0;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 11.5px;
        color: #666666;
        letter-spacing: 0.03em;
      }
    }


  }

  .post_text {
    font-family: Helvetica !important;
    line-height: 18px !important;
    /* or 129% */
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */

    letter-spacing: 0.03em;

    padding-left: 24px !important;
    padding-right: 24px !important;
    margin: 0;
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    p {
      letter-spacing: 0.03em !important;

      font-size: 14px !important;
      margin-bottom: 0;
      color: #191919;
    }
  }

  .post_feedback {
    height: 44px;
    background: url('/static/img/assessment/linkedin-post-feedback.png') no-repeat left 20px center;
    font-family: Helvetica;
    padding-left: 80px;
    font-size: 12px;
    line-height: 44px;
    letter-spacing: 0.03em;
    color: #666666;
    opacity: 0.9;
    display: flex;

    &.facebook {
      background: url('/static/img/assessment/post-feedback-like.png') no-repeat left 20px center;
      padding-left: 46px;
      justify-content: space-between;
      padding-right: 18px;
    }

  }
}

.cursor-pointer {
  cursor: pointer;
}

.seperate-buttons {
  &.full-width {
    .MuiToggleButtonGroup-root {
      display: block;
      // padding: 14px 28px;
      font-size: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  .MuiToggleButtonGroup-root {

    @media (max-width: 992px) {
      width: 100%;
    }

    .MuiButtonBase-root {
      padding: 12px 40px;
      margin: 0 2px;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px rgba(29, 30, 29, 0.06);
      border-radius: 4px;
      transition: ease 0.2s all;
      text-transform: none;
      border: 2px solid transparent;
      white-space: nowrap;

      @media (max-width: 992px) {
        margin-right: 0px;
        margin-top: 20px;
        width: 70%;
      }

      &:hover {
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08);
      }

      &:active {
        box-shadow: 0px 0px 4px rgba(29, 30, 29, 0.08);
        opacity: 0.6;
      }

      &.Mui-selected {
        border: 2px solid #4A4B4A;
        color: white;

        &:hover {
          color: white;
        }

      }

      &.MuiToggleButtonGroup-grouped {
        margin-right: 10px;
        border-radius: 4px;
      }

      @media (max-width: 992px) {
        font-size: 14px;
        font-weight: 700;
        margin: 5px;
      }
    }
  }
}

/* Material UI custom */
.assessment {
  .MuiTypography-root {
    font-family: 'Nunito Sans', sans-serif;
  }
}

.MuiTable-root {
  .MuiTableCell-head {
    color: rgba($heavymetal, 0.6);
    font-weight: 400;
    font-size: 14px;
  }

  .MuiTableBody-root {
    .MuiTableCell-root {
      font-size: 14px;;
      color: $heavymetal;

      &:nth-child(1) {
        font-size: 16px;
        font-weight: 700;

        @media (max-width: 992px) {
          font-size: 14px;

          div {
            font-size: 12px;
            color: rgba($heavymetal, 0.6);
            font-weight: 400;
          }
        }

      }

      padding: 5px 16px;
    }
  }
}

.MuiSlider-thumb {
  position: relative;
  margin-left: 2px;
  margin-top: -2px;

  &:before {
    content: " ";
    position: absolute;
    border: 3px solid $aspargus;
    z-index: 1;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-top: -10px;

  }
}

.MuiSlider-root {
  color: $aspargus;
}

.MuiSlider-mark {
  margin-top: -13px;
  border: 3px solid #afafaf;
  z-index: 1;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-left: -5px;

  &:before {
    content: " ";
    position: absolute;
    border: 3px solid white;
    background: white;
    top: 0px;
    left: 0px;
    z-index: 1;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    transition: ease 0.2s all;
  }

  &:hover {
    border: 3px solid #bfbfbf;
  }
}

.MuiSlider-markActive {
  margin-top: -13px;
  border: 3px solid $aspargus;
  z-index: 1;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-left: -4px;
  background: white;
  opacity: 1;

  &:before {
    content: " ";
    position: absolute;
    border: 3px solid $aspargus;
    background: $aspargus;
    top: 5px;
    left: 5px;
    z-index: 1;
    width: 18px;
    height: 18px;
    border-radius: 50%;

  }
}

.MuiSlider-markLabel {
  margin-top: 10px;
  margin-left: 8px;
}

.MuiSlider-thumb {
  &.MuiSlider-active {
    box-shadow: 0 0 0 14px rgba($aspargus, 0.2);
  }
}

.MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
  box-shadow: 0px -5px 0px 10px rgba(63, 181, 112, 0.16)
}

.yes-no-question {
  display: flex;
  background: white;
  box-shadow: 0px 4px 8px rgba(29, 30, 29, 0.06);
  border-radius: 4px;
  padding: 16px;
  justify-content: space-between;
  margin: 4px 0;
  align-items: center;

  .question {
    color: rgba(29, 30, 29, 0.8);
    font-weight: 600;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    @media (max-width: 992px) {
      font-size: 14px;
    }
  }

  .MuiTypography-root {
    margin-right: auto;
  }

  .MuiToggleButtonGroup-root {
    align-items: flex-end;

    .MuiButtonBase-root {
      background: #FBFBFB;
      font-weight: 500;

      &.MuiButtonBase-root {
        padding: 6px 16px;
        font-weight: 600;
      }
    }
  }

  .MuiFormControlLabel-label {
    margin-left: 10px !important;
  }

  .MuiFormControlLabel-label {
    padding-right: 2em;
  }

  &.incorrect {
    border: 2px solid rgba($score-0, 0.8);
    background-color: rgba($score-0, 0.1);
    color: $score-0;

    .MuiTypography-root {
      color: $score-0;
      margin-right: auto;
    }
  }

  &.correct {
    border: 2px solid rgba($score-100, 0.8);
    background: rgba($score-100, 0.1);
    color: $score-100;

    .MuiTypography-root {
      color: $score-100;
      margin-right: auto;
      font-weight: 700;
    }
  }
}

.MuiFormControlLabel-root {
  padding: 12px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(29, 30, 29, 0.06);
  border-radius: 4px;
  transition: ease 0.2s all;

  &:hover {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08);
  }

  &:active {
    box-shadow: 0px 0px 4px rgba(29, 30, 29, 0.08);
  }

  border: 2px solid rgba(#1D1E1D, 0);

  &.selected {
    border: 2px solid rgba(#1D1E1D, 0.8);

    &label {
    }
  }

  &.incorrect {
    border: 2px solid rgba($score-0, 0.8);
    background-color: rgba($score-0, 0.1);

    .MuiTypography-root {
      color: $score-0;
      font-weight: 700;
    }

    .MuiRadio-root {
      background: url('/static/img/assessment/icon-incorrect.svg') no-repeat center center;
      color: transparent;
    }
  }

  &.somewhat-correct {
    border: 2px solid rgba($score-50, 0.8);
    background-color: rgba($score-50, 0.1);

    .MuiTypography-root {
      color: $score-50;
      font-weight: 700;
    }

    .MuiRadio-root {
      background: url('/static/img/assessment/icon-somewhat-correct.svg') no-repeat center center;
      color: transparent;
    }
  }


  &.correct {
    .MuiRadio-root {
      background: url('/static/img/assessment/icon-correct.svg') no-repeat center center;
      color: transparent;
    }
  }

  &.user-correct {
    border: 2px solid rgba($score-100, 0.8);
    background: rgba($score-100, 0.1);

    .MuiTypography-root {
      color: $score-100;
      font-weight: 700;
    }

    &label {

    }
  }

  &.correct-but-irrelevant {
    border: 2px solid rgba(180, 180, 180, 0.8);

    .MuiTypography-root {
      color: $heavymetal;
      font-weight: 700;
    }

    &label {
    }

    .MuiRadio-root {
      background: url('/static/img/assessment/icon-correct-but-irrelevant.svg') no-repeat center center;
      color: transparent;
    }
  }


}

.MuiRadio-root {
  color: rgba(0, 0, 0, 0.34)

}

.MuiFormLabel-root {
  color: $heavymetal;
  opacity: 0.5;
  font-size: 14px;;
  padding-bottom: 4px;
}

/* MUI Buttons general */
.MuiButtonBase-root {
  font-family: 'Nunito Sans', sans-serif !important;

  &.MuiButton-root {
    padding: 14px 50px;
    background: #FFFFFF;
    border-color: #CDCECD;
    color: #1D1E1D;
    transition: ease 0.2s all;;
    font-size: 14px;
    box-shadow: 0px 4px 8px rgba(29, 30, 29, 0.06);
    border-radius: 4px;
    width: 100%;

    &:hover {
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08);
      background: #ffffff;
    }

    &:active {
      opacity: 0.6;
    }
  }
}


// Checkboxes
.MuiFormControlLabel-root {
  &:hover {
    .MuiButtonBase-root {
      &.MuiCheckbox-root {
        background: #E8EAE9;;
        border-radius: 2px;
        padding: 2px;
        margin: 10px;
      }
    }
  }

  .MuiButtonBase-root {
    &.MuiCheckbox-root {
      padding: 2px;
      margin: 10px;

      &.Mui-checked {
        border-radius: 4px;
        padding: 0px;
        margin: 12px;
      }
    }
  }

}


.select-from-text {
  height: 40px;
  width: 240px;
  border: 1px solid #e3e3e3;
  color: #616263;
  position: relative;
  border-radius: 3px;
  background: lighten($snowdrift, 10);
  border-radius: 5px;
  margin: 10px 0;
  font-size: 0.6em;

  @media (max-width: 992px) {
    text-align: right;
  }

  .title {
    position: absolute;
    top: -20px;
    left: 10px;
  }

  &:after {
    content: "";
    background: url('/static/img/icon-arrow-down.png') no-repeat center center;
    padding: 12px 8px;
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 1;
    text-align: center;
    width: 10%;
    height: 100%;
    pointer-events: none;
  }

  .select-form {
    height: 40px;
    width: 100%;
    padding: 5px 15px;
    background: none;
    border: 0;
    outline: none;
    font-size: 1.4em;
    -webkit-appearance: none; /* for webkit browsers */
    -moz-appearance: none; /* for firefox */
    appearance: none; /* for modern browsers */
  }
}

.MuiToggleButtonGroup-root {
  .MuiButtonBase-root {
    margin-right: 1px;
    background: #FFFFFF;
    color: #1D1E1D;
    transition: ease 0.2s all;
    flex-grow: 1;
    border: 1px solid #D2D2D2;
    border-radius: 4px;

    &:hover {
      background: #E8E8E8;
    }

    &:focus {
      //background: #DBDBDB;
      outline: none;
    }

    &.Mui-selected {
      background: #4A4B4A;
      color: white;
      border-color: #4A4B4A;

      &:hover {
        background: #4A4B4A;
      }
    }

    &.incorrect {
      border: 2px solid rgba($score-0, 0.8);
      background-color: rgba($score-0, 0.1);

      .MuiToggleButton-label {
        color: rgba($heavymetal, 0.9);
      }

      &:before {
        content: "";
        position: absolute;
        left: 10px;
        width: 20px;
        height: 20px;
        background: url('/static/img/assessment/icon-incorrect.svg') no-repeat center center;
      }
    }

    &.correct {
      //border: 2px solid rgba($score-100,0.8);
      .MuiToggleButton-label {
        color: rgba($heavymetal, 0.9);
      }

      &:before {
        content: "";
        position: absolute;
        left: 10px;
        width: 20px;
        height: 20px;
        background: url('/static/img/assessment/icon-correct.svg') no-repeat center center;
      }
    }

    &.user-correct {
      border: 2px solid rgba($score-100, 0.8);
      background: rgba($score-100, 0.1);
      position: relative;

      .MuiToggleButton-label {
        color: $score-100;
      }

      &label {

      }
    }

    .Mui-disabled {
      color: rgba($heavymetal, 0.9);

    }
  }
}

// Draggable list

.MuiListItem-root {
  .list-number {
    background: #E4F1EC;
    border-radius: 10px;
    height: 20px;
    width: 20px;
    color: $aspargus;
    font-weight: bold;
    font-size: 10px;
    line-height: 20px;
    text-align: center;

  }

  .list-text {
    background: #fff;
    margin: 0 0px 0 10px;
    padding: 15px 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    .MuiListItemText-primary {
      overflow-wrap: break-word;
      margin-right: 0;
      @media (max-width: 1400px) {
        max-width: 60%;
      }

      @media (max-width: 430px) {
        max-width: 55%;
      }
    }
  }


}

.MuiListItemSecondaryAction-root {
  .drag-handle {
    svg {
      position: absolute;
      top: 8px;
    }
  }

  .answer-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    left: -70px;

    &.incorrect {
      background: url('/static/img/assessment/icon-incorrect.svg') no-repeat left center;
    }

    &.correct {
      background: url('/static/img/assessment/icon-correct.svg') no-repeat left center;
    }
  }

}

.MuiListItem-container {
  .MuiListItemIcon-root {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    min-width: 50px;
    @media (max-width: 992px) {
      min-width: 40px;
    }
  }
}

.orderable-list-arrow {
  border: 1px solid #efefef;
  border-radius: 50%;
  width: 40px;

  .MuiButtonBase-root {
    background: none;
    //border: 1px solid #1D1E1D;
    box-shadow: none;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &.Mui-disabled {
      opacity: 0.2;
      background: none;
    }
  }

  .MuiFab-sizeSmall {
    @media (max-width: 992px) {

    }
  }

  @media (max-width: 992px) {
    transform: scale(0.8);
  }

}

.orderable-list-arrows {
  margin-right: 35px;
  display: flex;
}

.numerical-scale {
  position: relative;
  overflow: visible;
  padding-bottom: 10px;
  max-width: 100%;
  display: flex;
  height: 50px;
  margin-bottom: 30px;

  &:hover {
    .MuiButtonBase-root {
      label {
        opacity: 0 !important;
      }
    }
  }

  .MuiButtonBase-root {
    height: 50px;

    &:last-child {
      label {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding-right: 10px;
      }
    }

    &:nth-child(1) {
      label {
        justify-content: flex-start;
        padding-left: 10px;
      }
    }

    label {
      display: flex;
      width: 100%;
      justify-content: center;
      padding-right: 10px;
    }

    &:hover {
      label {
        opacity: 1 !important;
      }
    }

  }

  &.showLabels {
    .MuiButtonBase-root {
      &:nth-child(1) {
        label {
          opacity: 1;
        }
      }

      &:last-child {
        label {
          opacity: 1;
        }
      }
    }
  }

  .Mui-selected {
    label {
      opacity: 1 !important;
    }
  }

  label {
    opacity: 0;
    position: absolute;
    margin-top: 5px;
    font-size: 12px;
    bottom: -36px;
    left: 4px;
    text-transform: none;
    font-weight: 400;
    color: rgba($heavymetal, 0.6);
    white-space: nowrap;
    text-align: center;
    transition: ease 0.2s all;;

  }

}

.previous-btn {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: $aspargus;
  letter-spacing: 4%;
  cursor: pointer;
}

.mobile-subquestion {
  color: rgba($heavymetal, 0.6);
  font-size: 10px;
  font-weight: 800;
  padding: 10px 0;
  width: 100%;
}

// Question answers
.wizard-content {
  scroll-behavior: smooth;

  .question-answer-block {
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08);
    border-radius: 8px;
    padding: 20px 40px;
    width: 100%;
    flex-direction: column;
    display: flex;
    max-width: 80%;
    margin: 30px auto 20px auto;
    position: relative;
    z-index: 55;


    @media (max-width: 992px) {
      align-self: center;
      word-wrap: break-word;
      min-width: 100%;
      padding: 20px;
    }

    &.hide-arrow {
      &:before {
        display: none !important;
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      top: 1px;
      left: 130px;
      box-sizing: border-box;
      border: 10px solid black;
      border-color: transparent transparent #fff #fff;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(135deg);
      box-shadow: -2px 2px 1px rgba(0, 0, 0, 0.02);
    }

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      display: block;
      margin-bottom: 4px;
    }

    span:empty {
      padding-right: 0px;
    }

    span {
      padding-right: 4px;
    }

    .correct {
      color: $score-100;
    }

    .incorrect {
      color: $score-0;
    }

    p {
      margin-top: 10px;
      font-size: 14px;
      line-height: 20px;
      word-wrap: break-word;
    }
  }

  .question-answer-actions {
    h4 {
      margin-top: 10px;
      margin-bottom: 2px;
      font-size: 10px;
    }

    .action {
      font-size: 12px;
      line-height: 24px;
      font-weight: 500;
      color: rgba(29, 30, 29, 0.9);

      &.incorrect {
        padding-left: 20px;
        background: url('/static/img/assessment/icon-incorrect-simple.svg') no-repeat left center;
      }

      &.correct {
        padding-left: 20px;
        background: url('/static/img/assessment/icon-correct-simple.svg') no-repeat left center;
      }

      span {
        font-size: 0.9em;
        color: rgba(29, 30, 29, 0.4);
      }
    }

    ol {
      max-width: 350px;
      counter-reset: my-awesome-counter;
      list-style: none;
      padding-left: 30px;
      margin-top: 10px;

      li {
        margin: 0 0 0.5rem 0;
        counter-increment: my-awesome-counter;
        position: relative;

        &::before {
          content: counter(my-awesome-counter);
          color: #20A26B;
          font-size: 11px;
          font-weight: bold;
          position: absolute;
          --size: 18px;
          left: calc(-1 * var(--size) - 10px);
          line-height: var(--size);
          width: var(--size);
          height: var(--size);
          top: 4px;
          background: #E4F1EC;
          border-radius: 50%;
          text-align: center;
        }
      }
    }

    .details-header {
      text-transform: uppercase;
    }
  }
}

/* Mdlz specific */
.mdlz-newsletter {
  .mdlz-email-header {
    margin-top: 20px;
    margin-bottom: 10px;

    img {
      margin-left: -14px;
      max-width: 100%;
      width: 100%;
    }
  }

  .blue {
    padding: 16px 20px 5px 20px;
    font-size: 12px;
    background: #AFC5DB;
  }

  .img {
    display: flex;
    justify-content: center;

    img {
      padding: 10px 5px;
      width: 110px;
      padding-top: 26px;
    }
  }

  .mdlz-email-footer {
    padding: 10px 0;
    font-size: 10px;
    line-height: 140%;
    color: #000000;
    opacity: 0.7;
  }

  ul {
    padding-inline-start: 20px;
  }
}


// Animations

.fade-in {
  animation: 0.5s ease-out 0s 1 fadeIn;
}

.fade-out {
  animation: 0.5s ease-out 0s 1 fadeOut;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateX(0px);

  }
  100% {
    transform: translateX(-10px);
    opacity: 0;
  }
}

.Modal {
  &.assessment-mobile {
    display: flex;
    flex-direction: column;
    max-width: 92%;
    min-width: unset;
    padding: 20px 30px;
    border-radius: 4px;
    box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.1);
    text-rendering: optimizeLegibility;
    outline: none;
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    background: white;;
    text-align: center;

    h4 {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    p {
      color: $heavymetal;
      font-size: 13px;
      line-height: 20px;
    }
  }
}

.hoverable-image {
  width: 465px;
  height: 261px;
  position: relative;
  border-radius: 8px;
  margin-top: 30px;

  img {
    transition: ease-in-out 0.1s all;
    transform-origin: center center;
    cursor: pointer;
    opacity: 0.9;

    &:hover {
      opacity: 1;
      transform: scale(1.2, 1.2)
    }
  }

  @media (max-width: 992px) {
    transform: scale(0.7);
    align-self: center;
  }

}

.score-line {
  display: none;

  @media (max-width: 992px) {
    display: flex;
    width: 100%;
    min-height: 64px;
    justify-content: space-between;
    align-items: center;
    background-color: #F9FAF9;
    margin: 0;
    padding: 0px 20px;

    .score-details {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 800;
      color: rgba($heavymetal, 0.8);
      cursor: pointer;
      text-align: right;
    }

    .score-group {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 900;

      .score-in-words {
        font-size: 14px;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }
}

.modalText {
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
}

.mobileOnly {
  display: none;
  @media (max-width: 992px) {
    display: initial;
  }
}

.desktopOnly {
  display: initial;
  @media (max-width: 992px) {
    display: none;
  }
}

.desktopOnly--carousel {
  display: flex;
  @media (max-width: 992px) {
    display: none;
  }
}


.mobile-email {
  display: flex;
  flex-direction: column;

  .mobile-email-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    .mobile-email-left-side {
      display: flex;
      align-items: flex-end;
    }

    .mobile-email-header-details {
      display: flex;
      flex-direction: column;
      margin-left: 5px;

      .mobile-email-from {
        font-size: 14px;
        font-weight: 600;
      }

      .mobile-email-time {
        font-size: 12px;
        font-weight: 400;
        color: rgba($heavymetal, 0.6);
        margin: 0 5px;
      }

      .mobile-email-icons {
        margin-left: auto !important;
        background-color: pink !important;
      }
    }
  }
}
