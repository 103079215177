[dir=rtl] {
  .help-language {
    left: 10px;
    right: unset;
  }

  header {
    .header {
      .header-dropdown {
        .header-name {
          border-left: none !important;
          padding: 4px 16px 4px 50px;
          border-right: 0.05em solid rgba(52, 53, 52, 0.2);

          span {
            left: 4px;
            right: unset;
          }

          &:after {
            left: -10px;
            right: unset;
          }
        }
      }
    }
  }

  .navigation {
    &.back-button {
      padding-right: 30px;
      padding-left: 0;

      &:before {

        left: unset;
        right: 0;
        transform: rotate(180deg);
      }

    }
  }

  .sidebar {
    margin-right: 0;
    margin-left: 20px;

    .block-steps {
      margin-left: 20px;
      margin-right: 0px;

      div {
        padding-left: 10px;
        padding-right: 30px;
        border-left-width: 0;
        border-right-width: 4px;
        border-right-style: solid;

        &:after {
          right: unset;
          left: 10px;
        }
      }
    }
  }

  .certificate-button {
    display: none;

    button {
      &:after {
        display: none;
      }
    }
  }
}
