.scorm-player-container {
  width: 1280px;
  max-width: 100%;
  height: calc(100vh - 70px);
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 70px);
    border: 0px;
    border: 1px solid white;
    @media screen and (max-width: 992px) {
      padding-bottom: 70px;
    }

  }

  .preview {
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    position: relative;
    border: 1px solid #f9f9f9;

    img {
      width: 100%;
      height: 100%;
    }

    &:hover {
      &:after {
        width: 55px;
        height: 55px;
        background-size: 55px auto;
        opacity: 1;
      }
    }

    &:after {
      content: "";
      align-self: center;
      transition: ease 0.2s all;
      display: flex;
      align-content: center;
      justify-content: center;
      position: absolute;
      background-image: url('/static/img/icon-play.png');
      background-size: 50px auto;
      width: 50px;
      height: 50px;
      opacity: 0.8;
    }


  }
}
