.decision-player {
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  transition: ease 0.2s all;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);

  .question {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.04), 0 4px 24px rgba(29, 30, 29, 0.04);
    border-radius: 3px;
    padding: 20px 40px 20px 40px;
    position: relative;
    white-space: nowrap;
    color: white;
    animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    h1 {
      line-height: 1.5em;
      font-weight: 600;
      font-size: 1.8em;

      @media (max-width: 992px) {
        font-size: 1em;
      }
    }

  }

  .buttons {
    display: flex;
    flex-direction: row;

    button {
      margin: 10px 10px;
      animation: scale-up-center 0.5s ease;
      background: white;
      padding: 20px 40px;
      color: #000000;
      font-size: 16px;
      text-transform: unset;
      transform: ease 0.2s all;
      opacity: 0.8;

      @media (max-width: 992px) {
        font-size: 12px;
        padding: 10px;
      }

      &:hover {
        opacity: 1;
        background-color: rgba(255, 255, 255, 1) !important;
      }

      &:focus, &:active {
        box-shadow: 0 0 0 0.2rem #ffffff !important;
        background-color: rgba(255, 255, 255, 1) !important;
      }
    }


  }
}

.decision-video-item {
  .jw-controlbar {
    .jw-slider-time, .jw-icon-next, .jw-icon-rewind, .jw-related-btn, .jw-icon-fullscreen, .jw-svg-icon-settings {
      display: none !important;
    }

    &.jw-reset {
      //display: none!important;
    }
  }

  .jw-nextup-container {
    &.jw-reset {
      &.jw-nextup-sticky {
        &.jw-nextup-container-visible {
          //  display: none!important;
        }
      }
    }
  }

  .jw-title-primary {
    &.jw-reset-text {
      //display: none!important;
    }
  }

  .jw-wrapper {
    background: #3C3C3C;

    &.jw-reset {
      margin: 0 auto;

    }
  }
}

@keyframes scale-up-center {
  0% {
    display: none;
    opacity: 0;
    transform: scale(0.6);

  }

  1% {
    display: block;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;

  }
}

@keyframes slide-top {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(-0px);
  }
}
