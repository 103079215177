/*@import "../../node_modules/bootstrap/scss/bootstrap.scss";*/

@import "../../node_modules/bootstrap/scss/functions.scss";
@import "_variables";

@import "_react-datepicker-cssmodules";

@import "../../node_modules/bootstrap/scss/variables.scss";
@import "../../node_modules/bootstrap/scss/mixins.scss";
@import "../../node_modules/bootstrap/scss/print.scss";
@import "../../node_modules/bootstrap/scss/reboot.scss";
@import "../../node_modules/bootstrap/scss/type.scss";
@import "../../node_modules/bootstrap/scss/images.scss";
@import "../../node_modules/bootstrap/scss/code.scss";
@import "../../node_modules/bootstrap/scss/grid.scss";
@import "../../node_modules/bootstrap/scss/tables.scss";
@import "../../node_modules/bootstrap/scss/forms.scss";
@import "../../node_modules/bootstrap/scss/buttons.scss";
@import "../../node_modules/bootstrap/scss/transitions.scss";
@import "../../node_modules/bootstrap/scss/dropdown.scss";
@import "../../node_modules/bootstrap/scss/button-group.scss";
@import "../../node_modules/bootstrap/scss/input-group.scss";
@import "../../node_modules/bootstrap/scss/custom-forms.scss";
@import "../../node_modules/bootstrap/scss/nav.scss";
@import "../../node_modules/bootstrap/scss/navbar.scss";
@import "../../node_modules/bootstrap/scss/card.scss";
@import "../../node_modules/bootstrap/scss/breadcrumb.scss";
@import "../../node_modules/bootstrap/scss/pagination.scss";
@import "../../node_modules/bootstrap/scss/badge.scss";
@import "../../node_modules/bootstrap/scss/jumbotron.scss";
@import "../../node_modules/bootstrap/scss/alert.scss";
@import "../../node_modules/bootstrap/scss/progress.scss";
@import "../../node_modules/bootstrap/scss/media.scss";
@import "../../node_modules/bootstrap/scss/list-group.scss";
@import "../../node_modules/bootstrap/scss/close.scss";
@import "../../node_modules/bootstrap/scss/modal.scss";
@import "../../node_modules/bootstrap/scss/tooltip.scss";
@import "../../node_modules/bootstrap/scss/popover.scss";
@import "../../node_modules/bootstrap/scss/carousel.scss";
@import "../../node_modules/bootstrap/scss/utilities.scss";

@import "../../node_modules/react-credit-cards/lib/styles.scss";

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,400i,500,700,900');

@import "_buttons";
@import "_loading-spinner";
@import "_forms";
@import "_reactable";
@import "_animation";
@import "_accordion";
@import "_progress";
@import "_scorm";
@import "_help";
@import "_rtl";
@import "_assessment";

@mixin ie-only() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

body {
  background: #F0EFEF;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  color: $heavymetal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-align: unset;
}

.green {
  color: $aspargus;
}

.red {
  color: $shiraz;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.p0 {
  padding: 0;
}

.mb0 {
  margin-bottom: 0;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-regular {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.nowrap {
  white-space: nowrap;
}

.opacity08 {
  opacity: 0.8;
}

.hide-desktop {
  @media (min-width: 992px) {
    display: none;
  }
}

.hide-mobile {
  @media (max-width: 992px) {
    display: none;
  }
}

.hide-ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none !important;
  }
}

.text-size-09 {
  font-size: 0.9em;
}

.navbar-nav {
  &.navbar-flex-row {
    flex-direction: row;
  }
}

.mb10 {
  margin-bottom: 10px;
}

.mobile-mb-20 {
  @media (max-width: 992px) {
    margin-bottom: -20px;
  }
}

.header-link-manager {
  padding: 8px 14px 8px 14px;
  font-size: 0.9em;
  position: relative;
  z-index: 5;
  white-space: nowrap;
  transition: ease 0.2s all;
  border-radius: 3px;
}

.pull-right {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

}

.notification {
  color: white;
  padding: 5px;
  text-align: center;
  max-width: 880px;
  margin: 20px auto;
  border-radius: 2px;

  &.danger {
    background: rgb(226, 70, 70);

  }
}


.section-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 992px) {
    background: white;
    border-radius: 2px;
    padding: 20px 30px 10px 30px;
    margin-top: 20px;
  }

  .left {
    font-size: 0.8rem;

    .padded {
      padding: 10px 30px;
    }

    h3 {
      font-weight: 700;
      margin: 10px 0;
    }
  }

  .btns-right-align {
    display: flex;
    justify-content: flex-end;
    position: relative;

    .btn {
      margin-left: 20px;
    }

    &.start-training {
      a {
        width: 100%;
      }

      @media (max-width: 992px) {

        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        background: white;
        z-index: 1000;
        justify-content: center;
        text-align: center;

        .btn-primary {
          margin: 0;
          width: 100%;
          padding: 16px;
          font-size: 14px;
        }
      }
    }
  }
}

.navigation {
  &.back-button {
    margin-top: 20px;
    font-weight: 600;
    font-size: 14px;
    color: $heavymetal;
    padding-left: 30px;
    position: relative;
    transition: ease 0.2s opacity;
    opacity: 0.4;
    @media (max-width: 992px) {
      width: 20px;
      height: 20px;
      overflow: hidden;
    }

    &:before {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      left: 0px;
      top: 0px;
      background: url('/static/img/arrow-back.svg') no-repeat center center;
      background-size: 14px auto;
    }

    &:hover {
      opacity: 0.7;
    }
  }

}

.container {
  @media (max-width: 992px) {
    padding: 20px;
    padding-bottom: 50px;
  }

  &.max-1280 {
    max-width: 1280px;

  }
}

.training-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .navigation {
    flex: 2;
  }

  h1 {
    flex: 8;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;
    @media (min-width: 992px) {
      display: none;
    }
  }

  .info {
    width: 100%;
    flex: 2;
    text-align: center;
    @media (min-width: 992px) {
      display: none;
    }
  }
}

.manager-content {
  padding-top: 40px;

  h2 {
    margin: 30px 0;
  }

  h3 {
    margin: 20px 0;
  }

  .row {
    @media (max-width: 992px) {
      justify-content: center;
    }

    a {
      color: $aspargus;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}

.header-title-table {
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 5px 20px;
  background: lighten($desertstorm, 4);
  border-radius: 5px;

  .title {
    padding-top: 4px;
    font-size: 0.6em;
    opacity: 0.5;
  }

  .text {
    font-size: 0.8em;
  }
}

.table-white-bg {
  background: white;
  padding: 5px 20px;
  border-radius: 5px;
}

.simple-table {
  width: 100%;
  margin: 20px 0;
  font-size: 0.9em;

  &.white {
    background: white;
  }

  th {
    border-bottom: 2px solid #E7E5E1;
    margin-bottom: 4px;

  }

  .list-line {
    display: flex;
  }

  td {
    border-bottom: 1px solid #E7E5E1;
  }

  tr:first-child {
    border-top: none;
  }

  tr:last-child {
    border-bottom: none;
  }

  tr:nth-child(odd) td {
    background: rgb(245, 245, 245);
  }


  tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
  }

  td {
    background: #FFFFFF;
    padding: 3px 5px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 0.9em;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(221, 218, 218, 0.6);
  }

  td:last-child {
    border-right: 0px;
  }

  th.text-center {
    text-align: center;
  }
}

.table2 {
  width: 100%;
  margin: 20px 0;
  font-size: 0.9em;

  &.white {
    background: white;
  }

  th {
    border-bottom: 2px solid #E7E5E1;
    margin-bottom: 4px;

    &:first-child {
      border-top-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-right: none;
    }
  }

  .list-line {
    display: flex;
  }

  td {
    border-bottom: 1px solid #E7E5E1;
    line-height: 34px;
  }

  tr:first-child {
    border-top: none;
  }

  tr:last-child {
    border-bottom: none;
  }

  tr:nth-child(odd) td {
    background: rgb(245, 245, 245);
  }


  tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
  }

  td {
    background: #FFFFFF;
    padding: 3px 5px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 0.9em;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(221, 218, 218, 0.6);
  }

  td:last-child {
    border-right: 0px;
  }

  th.text-center {
    text-align: center;
  }
}

.manager-user-table {
  background: white;
  padding: 40px;

  th {
    font-size: 0.6em;
    width: 25px;
    font-weight: 100;
  }

  .user-title {
    font-size: 0.8em;
    color: darken($desertstorm, 40);
    font-weight: 100;

    &:hover {
      text-decoration: underline;
    }
  }

  table {
    th {
      border-bottom: 2px solid #E7E5E1;
      margin-bottom: 4px;

    }

    .list-line {
      display: flex;
    }

    td {
      border-bottom: 1px solid #E7E5E1;
    }
  }


  .user-title {
    padding: 4px;

    .icon {
      padding-right: 10px;
      float: left;

      img {
        width: 24px;
      }
    }
  }

  .tableHeader {
    justify-content: flex-end;
    align-content: flex-start;
    /* Campaign list */
    .date {
      margin-top: 20px;
      font-size: 0.8em;
      opacity: 0.6;
    }

    .list-line {
      overflow: hidden;
      background: white;
      font-size: 0.9em;
      border-radius: 3px;
      padding: 0.75rem;
      width: 100%;

      &.sideways {
        .col {
          transform: rotate(-82deg) translateX(-31px);
          padding-top: 4px;
          text-indent: -2px;
          position: relative;

          &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            right: -250%;
            z-index: 100;
            background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
            top: -56px;
          }
        }
      }

      .col {
        justify-content: flex-start;
        text-align: left;
        font-size: 13px;
        opacity: 0.6;;
        width: 25px;
        white-space: nowrap;
        left: -10px;
      }

      .percentage {
        color: $aspargus;
      }

      .icon {

        img {
          width: 20px;
        }
      }
    }

  }

  .col {
    width: 25px;
    min-height: 25px;

    .not-checked {
      width: 14px;
    }

    img {
      width: 20px;
      height: auto;
    }
  }
}

.dropdown {
  position: relative;

  &.right {
    float: right;
    margin-right: 25px;

  }

  .dropdown-box {
    position: absolute;
    transition: all 0.2s ease;
    display: hidden;
    opacity: 0;
    top: 0px;
    background: lighten($desertstorm, 10);
    left: 10px;
    padding: 8px 10px 5px 10px;
    margin-top: 35px;
    z-index: 1;
    border-radius: 2px;

    &.open {
      opacity: 1;
      top: 10px;
      visibility: visible;
    }

    .link {
      text-align: left;
      color: $heavymetal;
      font-size: 0.8em;
      line-height: 48px;

      &:hover {
        background: rgba($heavymetal, 0.05);
      }

      .img {
        width: 40px;
        float: left;
      }

      img {
        width: 26px;
      }
    }
  }
}


.user-table {
  .user-title {
    font-size: 16px;
    color: darken($desertstorm, 40);
    font-weight: 100;

    &:hover {
      text-decoration: underline;
    }
  }

  .tableHeader {
    display: flex;
    justify-content: flex-end;
    align-content: flex-start;
    margin-left: -8px;
    /* Campaign list */
    .date {
      margin-top: 20px;
      font-size: 0.8em;
      opacity: 0.6;
    }

    .list-line {
      overflow: hidden;
      background: white;
      font-size: 0.9em;
      border-radius: 3px;
      padding: 4px;

      &.sideways {
        .col {
          //transform: rotate(-82deg) translateX(-41px);
          margin-bottom: 55px;
          padding-top: 4px;
          //text-indent: -29px;
          position: relative;

          &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            right: -250%;
            z-index: 100;
            background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
            top: -56px;
          }
        }
      }

      .col {
        justify-content: flex-start;
        text-align: left;
        font-size: 13px;
        opacity: 0.6;;
        width: 25px;
        white-space: nowrap;
        left: -10px;
      }

      .percentage {
        color: $aspargus;
      }

      .icon {

        img {
          width: 20px;
        }
      }
    }

  }

  .col {

    width: 25px;
    min-height: 25px;
    text-align: center;

    .not-checked {
      width: 14px;
    }

    img {
      width: 20px;
      height: auto;
    }
  }
}

.hidden-id {
  margin-top: 10px;
  opacity: 0.2;
  text-align: right;
  font-weight: 100;
  font-size: 0.8em;

}

.list-table {
  font-size: 16px;
  color: darken($desertstorm, 40);

  .tableHeader {
    color: black;
    text-align: left;
  }

  .title {
    position: relative;
    margin-left: 6px;

    .checkmark {
      position: absolute;
      left: -7px;
      top: 2px;
      width: 16px;
    }
  }

  .progress {
    border-radius: 8px;

    .progressbar {
      border-radius: 8px;
      background: $aspargus;

    }
  }

}

.hidden {
  opacity: 0;
  display: none;
}

.datepicker_input {
  position: relative;
  margin-bottom: 10px;
  margin-right: 20px;

  .react-datepicker-wrapper {
    display: block;
  }

  .close {
    position: absolute;
    z-index: 1000;;
    left: -5px;
    top: -5px;
    width: 20px;
    height: 20px;
    background: $desertstorm;
    border-radius: 50%;
    text-align: center;

    img {
      margin-top: -6px;
      width: 10px;
    }
  }
}

.schedule {
  position: relative;

  &.disabled {
    display: none;
  }
}

.radio-holder {
  position: relative;

  .radio_absolute {
    position: absolute;

    .notify {
      label {
        margin-left: 400px;
      }
    }
  }
}

.radioFieldBig {
  border-radius: 5px;
  background: white;
  position: relative;
  padding: 40px;
  margin-bottom: 20px;

  .title {
    position: absolute;
    top: -25px;
    left: 40px;
    border-radius: 5px;
    height: 50px;
    color: $heavymetal;
    background: white;
    padding: 14px 30px;
    font-size: 1.1em;

    &:before {
      border: 5px solid $aspargus;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      left: 0;
      top: 0;
      position: absolute;
    }
  }
}

/* List line */
.list {
  .date {
    margin-top: 20px;
    font-size: 0.8em;
    opacity: 0.6;
  }

  .list-line {
    background: lighten($desertstorm, 10);
    border-radius: 3px;
    padding: 10px;
    margin-top: 5px;
    font-size: 0.9em;

    .icon {

      img {
        width: 20px;
      }
    }

    .percentage {
      color: $aspargus;
    }
  }
}

.list-image-horizontal {
  display: flex;
  margin-bottom: 20px;

  .content {
    height: 100%;
    text-align: center;
    border-radius: 4px;
    background: lighten($desertstorm, 8);
    padding: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: ease 0.2s backround;

    .text {
      padding: 5px;
      font-size: 0.8em;
    }

    .img {
      width: 100%;
    }

    img {
      max-height: 50px;
      max-width: 80%;
    }

    &:hover {
      background: lighten($desertstorm, 6);

    }
  }
}


.main-navigation {
  clear: both;
  display: block;
  float: left;
  width: 100%;

  ul {
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;

    ul {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
      float: left;
      position: absolute;
      top: 100%;
      left: -999em;
      z-index: 99999;

      ul {
        left: -999em;
        top: 0;
      }

      li {
        &:hover > ul,
        &.focus > ul {
          left: 100%;
        }
      }

      a {
        width: 200px;
      }
    }

    li:hover > ul,
    li.focus > ul {
      left: auto;
    }
  }

  li {
    float: left;
    position: relative;
  }

  a {
    display: block;
    text-decoration: none;
  }

}


.burger {
  position: fixed;
  padding: 10px;
  top: 4px;
  right: 10px;
  display: none;
  z-index: 99;
  border-radius: 5px;

  @media (max-width: 992px) {
    display: block;
  }

  li {
    width: 30px;
    height: 3px;
    background-color: rgba($heavymetal, 0.6);
    border-radius: 3px;
    margin: 5px 0;
    list-style: none;

    &:nth-child(2) {
      width: 26px;
    }

    &:nth-child(3) {
      width: 24px;
    }
  }
}

.burger.open li {
  background-color: #2e9aff;
}


.banner-top-right {
  background: lighten($desertstorm, 2);
  border-radius: 10px;
  height: 100px;
  padding: 10px;
  float: right;

  img {
    width: 200px;
  }
}

.overlay-black {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 50;
  pointer-events: none;
  opacity: 0;
  transition: ease 0.2s all;

  &.visible {
    top: 0;
    left: 0;
    pointer-events: auto;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: open .5s ease-in-out;
    transform: translate3d(0, 0, 0) ease-in-out 0.5s;
    animation: open .5s ease-in-out;
    visibility: visible;
    background: $heavymetal;
    opacity: 0.6;
  }

  &.visible-search {
    pointer-events: auto;
    transition: ease all 0.2s;
    visibility: visible;
    background: $heavymetal;
    opacity: 0.9;
  }
}

.header-line {
  margin-top: 30px;
  @media (max-width: 992px) {
    margin-top: 30px;
  }
}

.bg-white {
  .col {
    background: white;
  }
}

.sub-headline {
  opacity: 0.6;
  margin: 0 0 100px 0;
}

header {
  border-bottom: 1px solid #dcd9d3;
  z-index: 1000;
  transition: ease 0.2s all;
  background: white;
  color: $heavymetal;

  @media (max-width: 992px) {
    background: $snowdrift;
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 320px;
    right: -320px;

    &.open {
      right: 0;
    }
  }

  .container {
    @media (max-width: 992px) {
      padding: 0;
    }

    &.full {
      padding-bottom: 0;
      width: 100%;
      max-width: 1280px;
    }
  }

  .close {
    width: 30px;
    height: 30px;
    position: fixed;
    top: 20px;
    right: -60px;
    transition: ease-in-out right 0.2s;

    img {
      width: 100%;
    }

    &.open {
      right: 20px;
    }
  }

  .header {
    height: 60px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 992px) {

      min-height: 100vh;
      display: block;
      background: $snowdrift;
      overflow-y: scroll;
      .nav {
        align-self: auto !important;
        min-height: auto;

        ul {
          flex-direction: column;
        }
      }
    }

    .logo_box {
      display: flex;
      position: relative;
      width: 125px;
      align-items: center;
      @include ie-only() {
        width: inherit;
        height: inherit;
        justify-self: flex-start;
      }

      &.admin {
        &:before {
          position: absolute;
          content: "admin";
          top: 21px;
          right: 0;
          font-weight: 100;
          font-size: 10px;
          color: lighten($heavymetal, 40);
        }
      }

      img {
        max-width: 100%;
        max-height: 88%;
        height: auto;
        width: auto;
        @include ie-only() {
          max-width: 125px;
        }
      }

      @media (max-width: 992px) {
        display: none;
      }
    }

    .logo {
      @media (max-width: 992px) {
        display: none;
      }

      height: 100%;
      display: flex;
      justify-content: center;
      width: 300px;
      margin-top: 20px;

      img {
        height: 24px;
      }
    }

    .language {
      .nav-link.link {
        padding-bottom: 4px !important;
      }

      img {
        width: 20px;
      }
    }

    .header-title {
      align-self: center;
      font-weight: 700;
      font-size: 1em;
      @media (max-width: 992px) {
        display: none;
      }
    }

    .nav {
      align-content: center;
      height: 100%;
      justify-content: flex-end;
      min-width: 200px;
      display: flex;


      @media (max-width: 992px) {
        -webkit-padding-start: 0;
        height: unset;

      }

      .header-text {
        color: grey;
        font-size: 0.8em;
        text-align: right;
        flex: 1;
        margin-right: 13px;

      }

      .btn-header {
        padding: 10px 30px;
        align-self: center;
        font-size: 14px;
        font-weight: 600;
        color: white;
        text-transform: uppercase;
        justify-self: flex-end;
        display: flex;
        margin-left: auto;
        @media (max-width: 992px) {
          width: 100%;
          .btn {
            width: 100%;
          }
        }

      }

      li, .nav-link {
        margin: 0 15px;
        transition: ease all 0.2s;
        align-self: center;
        list-style: none;
        font-size: 0.8em;
        justify-content: space-around;
        font-weight: 500;
        border-bottom: 4px solid transparent;
        /* Hide the text. */
        transition: ease 0.2s all;

        padding: 18px 10px;
        margin: 0;
        @media (max-width: 992px) {
          color: $heavymetal;
          text-align: left;
          padding: 4px;
        }

        &.green {
          color: $aspargus;
        }

        &.help {
          font-size: 0.8em;
          opacity: 0.7;
        }

        &.selected {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 5px;
            width: 28px;
            height: 22px;
            background-image: url(/static/img/check.svg);
            background-repeat: no-repeat;
            background-size: 15px auto;
            background-position: 5px 4px;
          }
        }

        img {
          width: 20px;
          margin-right: 7px;
        }


        &.status {
          padding: 5px 5px;

          img {
            width: 20px;
            margin-right: 5px;
          }

          &:hover {
            background: none;
          }
        }


        @media (max-width: 992px) {
          border-radius: 0;
          margin: 0;
          padding: 0;
          border-bottom: 1px solid lighten($heavymetal, 70);

          &.selected {

          }

          .progress {
            display: none;
          }

        }

        &:hover {
          &.dropdown {
            border-bottom: 4px solid transparent;

          }

          @media (max-width: 992px) {
            border-bottom: 1px solid lighten($shiraz, 50);
          }
        }

        &.dropdown {
          &:hover {
            background: none;
          }
        }

        &.header-right {
          justify-self: flex-end;
          display: flex;
          @media (max-width: 992px) {
            justify-self: auto;
          }
        }


        &:hover {
          @media (max-width: 992px) {
            background: lighten($snowdrift, 10);
          }
        }

        &.login {
          a {
            color: $snowdrift;

            @media (max-width: 992px) {

              color: $shiraz;
            }
          }
        }

        &.freetrial {
          background: $snowdrift;

          @media (max-width: 992px) {
            background: $shiraz;
          }

          a {
            color: $shiraz;

            @media (max-width: 992px) {
              color: $snowdrift;
            }
          }
        }
      }
    }

    .header-dropdown {
      position: relative;
      z-index: 2;

      .company-list {
        .company {
          margin: 0;
          width: 100%;
          text-align: left;
          background: none;
          color: #343534;
          font-size: 0.9em;
          border-bottom: none;
          padding-left: 26px;
        }

        .selected-company {
          position: relative;
          color: #000;

          &:before {
            content: "";
            position: absolute;
            left: 0px;
            width: 40px;
            height: 40px;
            background-image: url('/static/img/check.svg');
            background-repeat: no-repeat;
            background-size: 16px auto;
            background-position: 8px 2px;
          }

          &:hover {
            border: none;

            &:before {
              content: "";
              position: absolute;
              left: 0px;
              width: 40px;
              height: 40px;
              background-image: url('/static/img/check.svg');
              background-repeat: no-repeat;
              background-size: 16px auto;
              background-position: 8px 2px;
              opacity: 0.4;
            }
          }
        }
      }

      .header-name {
        padding: 4px 50px 4px 16px;
        font-size: 0.9em;
        font-weight: 600;
        position: relative;
        z-index: 5;
        color: lighten($heavymetal, 20);
        border-left: 0.05em solid rgba($heavymetal, 0.2);

        .header-subtext {
          font-size: 0.8em;
        }

        @media (max-width: 992px) {
          border-left: none;
          text-align: right;
          padding: 10px 50px 10px 16px;
          border-bottom: 1px solid rgba($heavymetal, 0.05);

        }

        &:after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          right: -10px;
          top: 18px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid lighten($heavymetal, 60);
        }

        .company {
          text-align: right;
          font-weight: 100;
          font-size: 0.9em;
        }

        span {
          position: absolute;
          right: 4px;
          top: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          content: "";
          background: #F0EFEF;
          border-radius: 50%;
          width: 34px;
          height: 34px;
          font-weight: 600;
          @media (max-width: 992px) {
            top: 10px;
            right: 8px;

          }
        }
      }

      .header-small {
        color: $heavymetal;
        font-size: 0.7em;
        margin-bottom: 5px;
        text-align: left;
        opacity: 0.7;
      }

      @media (max-width: 992px) {

        .dropdown-toggle {
          display: none;
        }

        .dropdown-menu {
          display: block;
          position: relative;
          border-width: 0;
          padding: 0;
          margin: 0;
        }

        .dropdown-item {
          padding: .5rem 0rem;
          color: rgba(0, 0, 0, .5);
        }
      }

      .nav-dropdown {
        position: absolute;
        opacity: 1;
        top: 70px;
        visibility: visible;
        box-shadow: 0px 0px 21px -4px rgba(0, 0, 0, 0.17);
        right: 0;
        width: 200px;
        background: white;
        border-radius: 4px;
        z-index: 1000;
        @media (max-width: 992px) {
          position: static !important;
          box-shadow: none;
          width: 100%;

        }

        &:focus {
          outline: none;
        }

        li {
          width: 100%;
          text-align: left;
          background: none;
          color: #343534;
          font-size: 0.9em;
          border-bottom: none;
          padding: 5px;
          padding-left: 26px;
          margin: 0;
          @media (max-width: 992px) {
            border-bottom: 1px solid rgba($heavymetal, 0.05);
            padding: 10px 10px 10px 26px;
          }

          &:hover {
            color: rgba($heavymetal, 0.8);
            border: none;
          }

        }

        .certificate {
          opacity: 0.2;
        }

        a {
          &.nav-link {
            &.link {
              padding: 10px 2px 10px 30px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              &:hover {
                background: #efefef;
              }
            }

          }
        }


        &:after {
          content: "";
          position: absolute;
          right: 20px;
          top: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid white;
          margin-top: -10px;
          width: 0;
          height: 0;

          @media screen and (max-width: 992px) {
            display: none;
          }
        }

        &.open {
          opacity: 1;
          top: 70px;
          visibility: visible;
          z-index: 2;
          @media screen and (max-width: 992px) {
            position: static;
            visibility: visible;
            border-radius: 0;
            opacity: 1;
            padding: 0;
            margin: 0;
            background: #f1f1f1;
            box-shadow: none;
            margin-top: 20px;
            width: 100%;
          }
        }


        .btn {
          background: none;
          color: $heavymetal;
          text-align: center;
          width: 100%;
          font-weight: 500;
          text-transform: none;

          &.btn-secondary {
            padding: 9px 0;
            padding-left: 30px;
            border: none;
            text-align: left;
          }

          &:hover {
            background: rgba($heavymetal, 0.05);
          }
        }

        hr {
          margin: 0.5rem;
        }

        &.nav-dropdown-status {
          .not-link {
            width: 100%;
            text-align: center;
            border-bottom: 1px solid $desertstorm;
            background: none;
            color: lighten($heavymetal, 10);
            text-align: left;
            color: $heavymetal;
            font-size: 0.7em;
            line-height: 40px;

            &:hover {
              background: none;
            }

            .img {
              height: 38px;
              float: left;
              padding: 3px;
              margin-right: 10px;
              text-align: center;

              img {
                width: 18px;
              }
            }

            .right {
              float: right;
              font-size: 0.9em;
              font-weight: 100;

              .green {
                color: $aspargus;
              }

            }

            border: none;
          }

          .progress-link {
            border: none;
            margin-top: 5px;
            line-height: 22px;
            text-align: center;
            font-size: 0.8em;
            background: lighten($desertstorm, 4);
            border-radius: 5px;
          }

        }
      }

      .nav-button {
        padding: 8px 10px;
        width: 100%;
      }
    }
  }
}


.mobile-header {
  z-index: 90;
  transition: ease 0.2s all;
  background: white;
  height: 60px;
  box-shadow: 0px 0px 21px -4px rgba(0, 0, 0, 0.02);
  color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  @media (min-width: 992px) {
    display: none;
  }

  .logo_mobile {
    display: none;
    padding: 15px;

    @media (max-width: 992px) {
      display: block;
    }

    img {
      margin-top: 3px;
      max-height: 20px;
      max-width: 100%;
    }
  }
}

.admin_submenu {
  background: lighten($heavymetal, 20);
  color: white;
  padding-left: 200px;

  a {
    font-size: 0.8em;
    font-weight: 100;
  }

}


.flex-columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}


h3 {
  font-weight: 700;
  font-size: 20px;
  margin: 10px 0 10px 0;
}

h4 {
  text-align: left;
  font-weight: 700;
  font-size: 18px;
  margin: 10px 0;
}

h5 {
  margin: 20px 0 10px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.4em;

}

p {
  font-size: 12px;
  line-height: 16px;
  color: lighten($heavymetal, 40);
  margin-bottom: 10px;
}

.item {
  margin: 30px 0;
  position: relative;

  &:after {
    content: "";
    width: 26px;
    height: 26px;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50%;
    overflow: hidden;
  }

  &.users {
    &:after {
      background: url('/static/img/icon-news-users.svg') no-repeat center center;
    }
  }

  &.training {
    &:after {
      background: url('/static/img/icon-news-list.svg') no-repeat center center;
    }
  }

  h5 {
    margin-right: 30px;

    a {
      color: $heavymetal;
    }
  }

  &.item {
    h5 {
      margin-right: 30px;
      font-weight: 600;

      a {
        color: $heavymetal;
      }
    }
  }

  .video {
    .image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title {
        font-size: 14px;
        font-weight: 600;
        margin: 5px 0;
      }

      .time {
        font-size: 12px;
        font-weight: 100;
        margin: 5px 0;
      }

    }

  }
}

.cta {

  color: $aspargus;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.01em;

}

.dashboard {
  display: flex;
  max-width: 1120px;
  margin: 0 auto;
}

.assessment-list {
  .assessment-item {
    padding: 20px;
    margin: 16px;
    border: 1px solid transparent;
    transition: ease 0.2s all;

    &:hover {
      background: #E9F6F0;
      border: 1px solid rgba(32, 161, 107, 0.25);
      box-sizing: border-box;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08);
      cursor: pointer;
    }

    .image {
      display: flex;
      justify-content: center;
      height: 100%;
    }

    .description {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      @media (max-width: 992px) {
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 600;
        margin: 0;
      }
    }

    .action {
      display: flex;
      justify-content: center;
      align-self: center;
      height: 100%;

      button {
        align-self: center;
      }
    }

    .score {
      display: flex;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;

      span {
        padding-left: 20px;
        text-align: center;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top: -14px;
      }


      .progress-semicircle.small {
        width: 54px;
        padding-bottom: 5px;
      }
    }

    .score-and-results {
      align-items: center;
      justify-content: center;

      @media (max-width: 992px) {
        margin-bottom: 10px;
      }
      @media (max-width: 1200px) and (min-width: 992px) {
        flex-direction: column;
      }

      .no-margin-auto {
        margin: 10px initial !important;
        @media (max-width: 992px) {
          margin: 10px;
        }
      }

      .action {
        @media (max-width: 1200px) and (min-width: 992px) {
          margin-right: 15px;
          width: 120px;
        }
        @media (max-width: 992px) {
          margin-bottom: 15px;
        }
      }
    }

    svg {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        transform: rotate(-90deg);
      }
    }

    .semicircle + div {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top: -100px;
      }
    }

    .text {
      &.small {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          margin-top: -170px;
        }
      }
    }

    &.small {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top: -30px;
      }
    }
  }
}

.date-icon {
  width: 20px;
  height: 15px;
  float: left;
  opacity: 0.5;
  margin-right: 5px;

  &.done {
    background: url('/static/img/icon-date-done.svg') no-repeat center center;
  }

  &.due {
    background: url('/static/img/icon-date-due.svg') no-repeat center center;
  }

  &.expired {
    background: url('/static/img/icon-date-expired.svg') no-repeat center center;
  }
}

.training-list {
  flex: 1;
  box-shadow: 0px 4px 14px rgba(29, 30, 29, 0.01), 0px 0px 2px rgba(0, 0, 0, 0.01);
  padding: 10px;
  margin: 20px auto;

  .training-progress {
    padding: 30px 40px;
    margin: 16px;
    transition: ease 0.2s all;
    box-shadow: 0px 4px 14px rgba(29, 30, 29, 0.01), 0px 0px 2px rgba(0, 0, 0, 0.01);
    font-size: 14px;
    border-bottom: 1px solid #f5f5f5;

    &:last-child {
      border-bottom: none;
    }

    &.demo {
      position: relative;
      display: flex;
      height: 180px;

      &.assessment-demo {
        height: 100px;

      }

      .header {
        &:before, &:after {
          content: "";
          position: absolute;
          top: 20px;
          left: 20px;
          width: 40%;
          height: 20px;
          background-color: #F7F7F7;
          border-radius: 2px;;
        }

        &:after {
          right: 20px;
          left: unset;
          width: 30%;

        }
      }

      .footer {
        &:before {
          content: "";
          position: absolute;
          bottom: 20px;
          left: 20px;
          width: 30%;
          height: 20px;
          background-color: #F7F7F7;
          border-radius: 2px;;
        }

        &:after {
          right: 20px;
          left: unset;
          width: 30%;

        }
      }

      .line {
        &:before, &:after {
          content: "";
          position: absolute;
          top: 60px;
          left: 20px;
          width: 10%;
          height: 20px;
          background-color: #F7F7F7;
          border-radius: 2px;;
        }

        &.line2 {
          &:before, &:after {
            top: 98px;
          }
        }

        &:after {
          right: 20px;
          left: unset;

        }

        .progress {
          &.progress2 {
            &:before, &:after {
              top: 106px;
            }
          }

          &:before, &:after {
            content: "";
            position: absolute;
            top: 68px;
            left: 140px;
            width: 64%;
            height: 4px;
            background-color: #F7F7F7;
            border-radius: 2px;;
          }

          &:after {
            background-color: #E7E7E7;
            width: 40%;

          }

        }
      }
    }

    strong {
      font-weight: 600;
      color: lighten($heavymetal, 20)
    }

    &:hover, &.hovered {
      box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.08);
    }

    .training-progress-title {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 992px) {
        flex-direction: column;
        h3 {
          margin: 5px 0;
        }
      }

      .details-time {
        font-size: 0.8rem;
      }
    }

    .row {
      margin-top: 5px;
    }

    h3 {
      color: $heavymetal;
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }

    h4 {
      font-size: 0.8rem;
      font-weight: 600;
      color: lighten($heavymetal, 20);
    }

    .progress {
      border: 0;
      margin-top: 10px;
      height: 0.3rem;
      padding: 0;
      background: #F0EFEF;
      border-radius: 3px;
      display: block;

      .progress-bar {
        border-radius: 3px;
        height: 4px;
      }

    }
  }
}

.add-item {
  position: relative;

  margin-top: 10px;
  padding: 5px 15px 5px 36px;
  border-radius: 5px;
  background: lighten($desertstorm, 5);
  display: inline-block;
  font-size: 0.8em;
  transition: ease all 0.2s;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    background: $aspargus;
    color: white;
  }

  &:before {
    position: absolute;
    content: " ";
    width: 20px;
    height: 20px;
    left: 8px;
    top: 5px;
    background: url('/static/img/icon-plus.svg') no-repeat center center;
    background-size: 14px auto;

  }
}

.alert {
  text-align: center;
  margin-bottom: 0;
  border: 0;

  &.header {
    border-radius: 0;
    margin-top: -1px;
  }

  &.alert-success {
    border: none !important;
    background: $aspargus;
    color: white;

    a {
      color: white;
      padding: 4px 10px;
      border-radius: 3px;
      font-weight: 100;
      background: $aspargus;
      border: none;
    }
  }

}

.overall-progress {
  border-radius: 10px;
  box-shadow: 0px 0px 21px -4px rgba(0, 0, 0, 0.07);
  background: white;
  padding: 20px;
  margin-bottom: 20px;

  .decoration {

    width: 100%;
    justify-content: center;
    align-content: center;

    img {
      width: 80px;
    }
  }

  .title {
    opacity: 0.6;
  }

  .good {
    color: $aspargus;
  }

  .bad {
    color: $shiraz;
  }

}

.copytext {
  border-radius: 5px;
  margin-top: 10px;
  padding: 8px 6px 8px 12px;;
  color: white;
  background: lighten($snowdrift, 5);
  display: inline-block;

  a {
    color: white;
  }
}

.copy-to-clipboard-btn {
  border: 1px solid $aspargus;
  color: $aspargus;
  background: lighen($aspargus, 10);
  padding: 3px 10px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 0.8em;
  transition: ease 0.2s all;

  &:hover {
    color: white;
    background: $aspargus;
  }
}

.step-info {
  background: white;
  padding: 40px;
  text-align: center;
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  img {
    max-width: 60px;
    align-self: center;

  }

  &.step1 {
    img {
      align-self: center;
      width: 60px;
    }
  }

  h4 {
    padding: 20px 0;
  }

  p {
    font-size: 0.8em;
    margin: 10px 0;
  }

  .btn {
    justify-self: flex-end;
  }

  &.step1 {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 1px solid $desertstorm;
  }

  &.step3 {
    border-left: 1px solid $desertstorm;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  span {
    font-size: 0.8em;
    opacity: 0.6;
  }
}

.progress-bar {
  border-radius: 8px;
  padding: 0;

  #steps {
    width: 505px;
    margin: 10px auto;
  }

  .step {
    width: 40px;
    height: 40px;
    background-color: $white;
    display: inline-block;
    border-color: transparent;
    border-radius: 50%;
    color: darken($desertstorm, 10);
    text-align: center;
    line-height: 36px;

    img {
      width: 40px;
      height: auto;
      opacity: 0.2;
    }
  }

  .step:first-child {
    line-height: 40px;
  }

  .step:nth-child(n+2) {
    margin: 0 0 0 100px;
    transform: translate(0, -4px);
  }

  .step:nth-child(n+2):before {
    width: 55px;
    height: 3px;
    display: block;
    background-color: darken($desertstorm, 10);
    transform: translate(-75px, 21px);
    content: '';
  }

  .step:after {
    width: 150px;
    display: block;
    transform: translate(-55px, 3px);
    color: darken($desertstorm, 20);
    content: attr(data-desc);
    font-weight: 400;
    font-size: 13px;
  }

  .step:first-child:after {
    transform: translate(-55px, -1px);

  }

  .step.active {
    //border-color: $aspargus;
    color: white;
    //background-color: $aspargus;
    img {
      opacity: 1;
    }
  }

  .step.active:before {
    background: linear-gradient(to right, darken($desertstorm, 20) 0%, $aspargus 100%);
  }

  .step.active:after {
    color: $aspargus;
    font-weight: 700;
    font-size: 0.8em;;

  }

  .step.done {
    background-color: $aspargus;
    border-color: $aspargus;
    color: $white;
  }

  .step.done:before {
    background-color: $aspargus;
  }

  .step1 {
    margin: 20px auto;
    background: lighten($snowdrift, 10);
    border: 1px solid darken($desertstorm, 10);
    border-radius: 5px;
    text-align: center;
  }
}

.leftMenu {
  line-height: 1.8em;
  font-size: 0.9em;
}

/* Forms buttons and progress stuff  */
.error {
  color: $shiraz;
  margin: 5px;
  font-size: 0.9em;
}


.error-box {
  padding: 15px 30px;
  border-radius: 5px;
  color: white;

  &.error-warning {
    background: $goldengrass;
    position: relative;
    padding-left: 40px;

    &:before {

      content: "";
      background: url('/static/img/icon-alert.svg') no-repeat center center;
      left: 10px;
      top: 10px;
      margin-top: -10px;
      background-size: 30px auto;
      position: absolute;
      width: 30px;
      height: 100%;
    }

  }
}

.steps {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  .header-img {
    margin-bottom: 30px;

    img {
      align-self: center;
      max-height: 100px;
    }
  }

  .forms {
    max-width: 430px;
    align-self: center;
    margin-bottom: 50px;

    .form-header {
      margin-top: 50px;

      h2 {
        color: $shiraz;
      }

    }

    .form-holder {
      max-width: 100%;

      h3 {
        font-size: 0.75em;
        padding: 20px 0 0px 0;
        margin-bottom: 10px;
      }

      p {
        &.small {
          font-size: 0.9;
        }
      }

      input {
        text-align: center;
        height: 40px;
        border: 1px solid darken($desertstorm, 10);
        width: 100%;
        border-radius: 4px;
        background: white;

        &::placeholder {
          color: darken($desertstorm, 10)
        }

        &:focus {
          outline: 1px solid $aspargus;
        }

      }

      select {
        height: 40px;
        border: 1px solid darken($desertstorm, 10);

        &:focus {
          outline: 1px solid $aspargus;
        }
      }
    }

    .btn {
      margin-top: 20px;
      width: 100%;
    }
  }
}

select {
  border: 1px solid darken($desertstorm, 10);

  &:focus {
    outline: 1px solid $aspargus;
  }
}

.form-bigger {
  margin-top: 50px;
}

.bigInput {
  padding: 8px;
  width: 100%;
  border: 8px solid darken($desertstorm, 5);
  border-radius: 5px;
  transition: ease 0.2s all;

  &:focus {
    border: 8px solid rgba($aspargus, 0.5);
    outline: none;
  }

  &.valid {
    border: 8px solid $aspargus;
    outline: none;
  }
}

.dropdownInput {
  padding: 8px;
  width: 100%;
  border: 4px solid darken($desertstorm, 5);
  border-radius: 5px;
  transition: ease 0.2s all;

  &:focus {
    border: 4px solid rgba($aspargus, 0.5);
    outline: none;
  }

  &.valid {
    border: 8px solid $aspargus;
    outline: none;
  }
}

/* Custom checkbox */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 50px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: lighten($heavymetal, 15);
  margin-bottom: 0;

  &.small {
    font-size: 16px;

    .checkmark {
      top: 6px;
      left: 0;
      height: 22px;
      width: 22px;
    }

    .checkmark:after {
      left: 5px;
      top: 2px;
      width: 9px;
      height: 12px;
      border-width: 0 4px 4px 0;
    }

    input:checked {
      ~ .checkmark {
        .container-checkbox input:checked ~ .checkmark {
          background-color: #eeeeee;
        }

        &:after {
          border-width: 0 4px 4px 0 !important;
        }
      }
    }

    &:hover {
      .checkmark {
        &:after {
          left: 5px;
          top: 2px;
          width: 9px;
          height: 12px;
          border-width: 0 4px 4px 0;
        }
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    width: 32px;
    background-color: white;
    border: 2px solid $aspargus;
    border-radius: 4px;
  }


  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  &:hover {
    .checkmark {
      background-color: white;

      &:after {
        display: block;
        left: 8px;
        top: 2px;
        width: 12px;
        height: 21px;
        border: solid rgba($aspargus, 0.2);
        border-width: 0 6px 6px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .checkmark:after {
    left: 8px;
    top: 2px;
    width: 12px;
    height: 21px;
    border: solid $aspargus;
    border-width: 0 6px 6px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  input:checked {
    ~ .checkmark {
      .container-checkbox input:checked ~ .checkmark {
        background-color: #eeeeee;
      }

      &:after {
        display: block;
        border: solid rgba($aspargus, 1) !important;
        border-width: 0 6px 6px 0 !important;


      }
    }
  }

}

/* Custom radiobutton */
.container-radiobutton {
  display: block;
  position: relative;
  padding-left: 50px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: lighten($heavymetal, 15);
  margin-bottom: 0;

  &.checked {
    .radiomark {
      &:after {
        display: block;
        background: rgba($aspargus, 1) !important;
      }
    }
  }

  &.small {
    font-size: 16px;

    .radiomark {
      top: 6px;
      left: 0;
      height: 22px;
      width: 22px;
    }

    .radiomark:after {
      left: 5px;
      top: 2px;
      width: 12px;
      height: 12px;
      background: $aspargus;
      border-radius: 50%;
    }

    input:checked {
      &.checked {
        border-width: 0 4px 4px 0 !important;

      }

      ~ .radiomark {
        .container-radiobutton input:checked ~ .radiomark {
          background-color: #eeeeee;
        }

        &:after {
          border-width: 0 4px 4px 0 !important;
        }
      }
    }

    &:hover {
      .radiomark {
        &:after {
          left: 3px;
          top: 3px;
          width: 12px;
          height: 12px;
          background: rgba($aspargus, 0.6);
        }
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

  }

  .radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    width: 32px;
    border: 2px solid $aspargus;
    border-radius: 50%;

    &.checked {
      ~ .radiomark:after {
        .container-radiobutton input:checked ~ .radiomark {
          background-color: #eeeeee;
        }

        &:after {
          display: block;
          border: solid rgba($aspargus, 1) !important;
          border-width: 0 6px 6px 0 !important;

        }
      }
    }
  }


  .radiomark:after {
    content: "";
    position: absolute;
    display: none;
  }

  &:hover {
    .radiomark {
      background-color: white;

      &:after {
        display: block;
        left: 4px;
        top: 4px;
        width: 20px;
        height: 20px;
        background: rgba($aspargus, 0.2);
      }
    }
  }

  .radiomark:after {
    left: 4px;
    top: 4px;
    width: 20px;
    height: 20px;
    background: $aspargus;
    border-radius: 50%;

  }


}


.big-green {
  font-size: 1.4em;
  font-weight: 700;
  color: $aspargus;
}


/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
  display: block;
}


a {
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.main-content {
  padding-top: 30px;
  position: relative;
}

.radio-content {
  transition: ease 0.2s all;
  height: none;

  &.disabled {
    opacity: 0.2;;
    height: 50px;
    overflow: hidden;
  }

  h5 {
    font-size: 0.8em;
    margin-top: 20px;
  }

  a {
    color: $aspargus;
  }

  .links {
    margin-top: 20px;
    border-radius: 5px;
    padding: 20px;
    background: lighten($desertstorm, 8);
  }

  .input-title {
    margin-top: 20px;
  }

  .input-subject {
    margin: 0 0 20px 0;

    input {
      padding: 10px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid lighten($heavymetal, 40);
    }

  }

  .input-textarea {
    margin: 20px 0;

    textarea {
      div {

        white-space: pre-line;
      }

      border-radius: 4px;
      border: 1px solid lighten($heavymetal, 40);
      outline: none;
      padding: 10px;
      width: 100%;
      min-height: 300px;

    }
  }
}

.form-holder {
  &.disabled {
    opacity: 0.5;;
  }
}

.create-campaign {
  &.categories {
    .form-holder {
      margin: 30px 0;

      &.padded {
        padding: 30px 10px;
        background-color: transparent;
      }
    }

  }

  h2 {
    margin: 20px;
  }

  .form-holder {
    margin: 30px 0;

    &.padded {
      padding: 30px 10px;
      background-color: lighten($desertstorm, 5);
    }
  }

  .line {
    background-color: lighten($desertstorm, 10);
    padding: 3px 5px;
    border-radius: 3px;
    margin-top: 10px;

    .title {
      padding-top: 5px;
      font-size: 0.9em;

    }

    .subtitle {
      font-size: 0.7em;
      opacity: 0.6;
    }

    .date {
      font-size: 0.61em;
      color: $aspargus;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .img {
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        border-radius: 5px;
      }
    }
  }

  h3 {
    margin: 30px 0 10px 0;
  }

  p {
    font-size: 0.9em;
  }

  .languages {
    padding: 20px;
    background: lighten($desertstorm, 2);
    border-radius: 5px;
    display: flex;

    .domain {
      margin-right: 20px;
    }

    .language {
      margin-right: 20px;
    }
  }

  .item-box-holder {
    margin-bottom: 50px;

  }

  .item-box-category-holder {
    border-radius: 5px;
    background: lighten($desertstorm, 4);
    display: block;
    padding-bottom: 3px;
    margin-bottom: 20px;
  }

  .item-box-category-title {
    padding: 20px 10px;
    font-size: 1.3em;
    color: darken($snowdrift, 50);
  }

  .item-box {
    padding: 10px;
    background: lighten($desertstorm, 9);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    position: relative;
    transition: ease 0.2s all;
    overflow: hidden;
    height: 100%;
    z-index: 500;

    &.disabled {
      color: white;
      background-color: rgba($heavymetal, 0.5);
      pointer-events: none;
      opacity: 0.5;

      &:after {
        top: 0;
        opacity: 0.95;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 500;
        content: "";
        background-image: url("/static/img/ribbon-disabled.svg");
        background-size: 150px auto;
        background-position: center top -4px;
        background-repeat: no-repeat;
        z-index: 110;
        left: 0;
        border-radius: 5px;
        transition: ease 0.2s all;
      }

    }

    &:before {
      opacity: 0;
    }

    &.selected {
      background: rgba($aspargus, 1) !important;
      color: white;

      &:before {
        opacity: 1;
      }

      &:after {
        opacity: 0.95;
        top: 0;
      }
    }

    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 500;
      content: "";
      background-image: url("/static/img/ribbon-selected.svg");
      background-size: 150px auto;
      background-position: center top -4px;
      background-repeat: no-repeat;
      background-color: rgba($aspargus, 0.1);
      z-index: 110;
      left: 0;
      top: -20px;
      border-radius: 5px;
      opacity: 0;
      transition: ease 0.2s all;
    }

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 500;
      content: "";
      background-image: url("/static/img/icon-checked-green.svg");
      background-position: right 10px bottom 10px;
      background-repeat: no-repeat;
      background-color: rgba($aspargus, 0.1);
      z-index: 110;
      left: 0;
      top: 0;
      border-radius: 5px;
    }

    &:hover {
      background: rgba($aspargus, 0.5);
      color: white;

      &:before {
        opacity: 1;
      }

      img {
        opacity: 0.3;
      }

    }

    .title {
      padding: 5px 0 0 0;
      font-size: 0.9em;
    }

    p {
      font-size: 0.75em;
      opacity: 0.6;
    }

    img {
      max-width: 100%;
      position: relative;
      z-index: 1;
      transition: ease 0.2s all;
    }

  }

  .preview-button {
    padding: 4px 8px;
    font-size: 0.8em;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    color: $heavymetal;
    opacity: 0.6;
    font-weight: 100;
    background-color: lighten($desertstorm, 20);
    text-align: center;
    transition: ease 0.2s opacity;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .pricing-box {
    position: fixed;
    padding: 8px;
    bottom: -220;
    right: 20px;
    width: 320px;
    height: 200px;
    background: $calypso;
    z-index: 10000;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &.visible {
      bottom: 0;
    }

    .left {
      padding-left: 10px;

      img {
        margin-right: 10px;
      }
    }

    .right {
      padding-right: 10px;
    }

    select {
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      padding-right: 20px;
      position: relative;
      font-size: 0.9em;


    }

    label {
      position: relative;

      &:after {
        position: absolute;
        content: "";
        right: 0px;
        width: 30px;
        height: 30px;
        background: url("/static/img/icon-arrow-down.svg") no-repeat right bottom 4px;
        pointer-events: none;

      }

    }

    .header {
      display: flex;
      color: white;
      height: 50px;
      line-height: 50px;
      justify-content: space-between;

    }

    .innerbox {
      box-shadow: 0px -3px 26px 0px rgba(0, 0, 0, 0.02);
      background: lighten($calypso, 1);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .price-line {
      padding: 0 10px;
      color: white;
      font-weight: 100;
      font-size: 0.8em;
      display: flex;
      justify-content: space-between;
      line-height: 40px;
      background-image: url("/static/img/icon-arrow-right-green.svg");
      background-position: center center;
      background-repeat: no-repeat;
      border-bottom: 1px solid darken($calypso, 1);
      display: none;

      &.visible {
        display: flex;
      }

      &.price {
        font-size: 0.9em;
        font-weight: 500;
      }
    }

    .final-price {
      color: white;
    }
  }

}

.progress-holder {
  background: lighten($desertstorm, 2);
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 100;
  box-shadow: -1px -1px 14px -1px rgba(0, 0, 0, 0.1);

  .list {
    flex: 1;
    background: lighten($desertstorm, 4);
    content: " ";
    background-image: url("/static/img/icon-list.png");
    background-repeat: no-repeat;
    background-size: 30px auto;
    background-position: top 30px center;
    font-size: 0.8em;
    text-transform: uppercase;
    text-align: center;
    padding-top: 56px;
    color: darken($desertstorm, 15);
    transition: ease 0.2s all;
    cursor: pointer;

    &:hover {
      background-color: lighten($desertstorm, 6);
      background-position: top 26px center;
      padding-top: 60px;

    }
  }

  .content {
    flex: 6;
    display: flex;
    position: relative;

    .info {
      flex: 6;

      .pretitle {
        padding: 10px 10px 4px 10px;
        font-size: 0.8em;
        opacity: 0.6;
        @media screen and (max-width: 992px) {
          font-size: 0.8em;
          line-height: 1.1em;
        }
      }

      .title {
        padding: 0 10px;
        font-size: 0.8em;
        opacity: 0.8;
        @media screen and (max-width: 992px) {
          font-size: 0.6em;
          line-height: 1.4em;
        }
      }

      .subtitle {
        font-size: 0.7em;
        opacity: 0.6;
        margin: 20px 0 0 10px;
      }

      .progress {
        height: 6px;
        margin: 5px 6px 10px 10px;
        border-radius: 10px;
        opacity: 0.8;
        transition: ease 0.2s all;

        .progressbar {
          width: 50%;
          background: $aspargus;
        }

      }
    }

    .status {
      flex: 3;
      text-align: right;

      img {
        margin-right: 6px;
        margin-top: 6px;

      }

      .icon {
        border: 5px solid white;
        border-radius: 50%;
        width: 82px;
        height: 82px;
        margin-top: 10px;
        background: #F1EFEA;
        box-shadow: 3px 4px 30px -8px rgba(59, 55, 55, 0.18);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba($aspargus, 0.7);
        font-size: 1.8em;
        font-weight: bold;
        float: right;
        margin-right: 10px;
        padding-left: 4px;

        span {
          font-size: 0.3em;
          color: rgba($aspargus, 0.5);
          padding-left: 2px;
        }

        img {
          width: 50px;
          margin-left: 4px;
        }

      }
    }
  }

  .next-subject {
    flex: 1;
    background: lighten($desertstorm, 4);
    background: lighten($desertstorm, 4);
    position: relative;
    content: " ";
    background-image: url("/static/img/icon-arrow-right.png");
    background-repeat: no-repeat;
    background-size: 10px auto;
    background-position: top 30px center;
    font-size: 0.6em;
    text-transform: uppercase;
    text-align: center;
    padding-top: 56px;
    color: darken($desertstorm, 15);
    transition: ease 0.2s all;
    cursor: pointer;

    &:hover {
      background-color: lighten($desertstorm, 6);
      background-position: top 26px center;
      padding-top: 60px;

      .content-next {
        opacity: 1;
        right: 100%;
        background-color: lighten($desertstorm, 6);
        width: 200px;
      }

    }

    .content-next {
      position: absolute;
      top: 0;
      opacity: 0;
      right: 90%;

      background: none;
      width: 50px;
      height: 100%;
      display: flex;
      align-content: center;
      align-items: center;
      transition: ease 0.2s all;

      .title {
        padding: 0 10px;
        font-size: 1.8em;
        opacity: 0.8;
      }
    }
  }
}

.text-smaller {
  font-size: 0.8em;
  font-weight: 100;
}

.done-animation {
  position: fixed;
  width: 240px;
  height: 150px;
  top: 50%;
  margin-top: -75px;
  left: 50%;
  margin-left: -120px;
  background: $aspargus;
  border-radius: 5px;
  z-index: 500;
  color: white;
  box-shadow: 2px 10px 29px -11px rgba(0, 0, 0, 0.48);
  padding: 20px;
  text-align: center;
  display: none;

  &.play {
    display: block;
    animation: subjectDone 1.5s normal;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.copied-animation {
  position: fixed;
  width: 180px;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  left: 50%;
  margin-left: -90px;
  background: $aspargus;
  border-radius: 5px;
  z-index: 500;
  color: white;
  box-shadow: 2px 10px 29px -11px rgba(0, 0, 0, 0.48);
  padding: 10px;
  text-align: center;
  display: none;

  &.play {
    display: block;
    animation: subjectDone 1.5s normal;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes subjectDone {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
    transform-origin: top;
    display: block;
  }
  20% {
    transform-origin: top;
    opacity: 0.9;
    transform: scale(1.1);
  }
  40% {
    transform-origin: top;
    opacity: 1;
    transform: scale(0.89);
  }
  50% {
    transform: scale(1) translate3d(0, 0, 0);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }

}

/* Loading indicators */
.loading-headline {
  width: 360px;
  height: 60px;
  background: lighten($desertstorm, 3);
  position: relative;
  margin-bottom: 50px;

  &:after {
    position: absolute;
    content: "";
    bottom: -25px;
    background: lighten($desertstorm, 3);
    width: 400px;
    height: 20px;
  }
}

.loading-group {
  width: 100%;
  border-radius: 4px;
  height: 120px;
  position: relative;
  background: rgba(255, 255, 255, .8);
  margin-bottom: 20px;

  &:after {
    position: absolute;
    content: "";
    top: 25px;
    left: 220px;
    background: rgba($desertstorm, .8);
    width: 180px;
    height: 20px;
  }

  &:before {
    position: absolute;
    content: "";
    top: 20px;
    left: 30px;
    background: rgba($desertstorm, .8);
    width: 180px;
    height: 80px;
  }
}

h1.spinner {
  font-size: 1.4em;
  font-weight: 400;
  color: rgba($color: #000000, $alpha: 0.4);
  width: auto;
}

.sign-in-form {
  text-align: center;
  padding-top: 2em;
  height: 100vh;
  position: relative;
  z-index: 1;

  h1 {
    font-size: 1.8em;
    font-weight: 600;
    text-transform: uppercase;
  }

  .form-text {
    color: white;
  }

  input[type="email"] {
    max-width: 380px;
    margin: 0 auto;
    text-align: center;
    background: white;
    height: 50px;
    border: 1px solid lighten($heavymetal, 40);
    font-size: 22px;
  }

  .form-text {
    margin-top: 10px;
  }

  .trial-link {
    color: $aspargus;
  }
}

pre {
  background-color: #333;
  border-color: #333;
  color: #eee;
  padding: 10px 20px;
  border-radius: 5px;
}

h1 {
  font-size: 22px;
  margin: 10px 0;
  font-weight: 600;
}

h2 {
  font-size: 32pt;
  line-height: 36pt;
  margin-bottom: .5em;
  font-weight: 300;
}

strong {
  font-weight: 600;
}

.nav-item {
  cursor: pointer;

  &:hover {
    a {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 992px) {
    width: 100%
  }
}


.toggleVisibilitiBtn {
  width: 100%;
  height: 40px;;
  background: lighten($desertstorm, 5);
  color: $aspargus;
  line-height: 36px;
  width: 300px;
  border-radius: 5px;
  border: 2px solid $aspargus;
  text-align: center;
  position: relative;
  padding-right: 30px;
  margin: 0 auto 30px auto;
  transition: ease background-color 0.2s;

  &:hover {
    background: lighten($desertstorm, 15);
  }

  &:before {
    content: "";
    position: absolute;
    background-image: url("/static/img/icon-arrow-left.svg");
    background-repeat: no-repeat;
    right: -10px;
    top: 4px;
    width: 40px;
    height: 40px;
    transform: rotate(-90deg);
    transition: ease 0.2s all;
    transform-origin: 10px 10px;
  }

  &.visible {
    &:hover {
      &:before {
        top: 16px;
      }
    }

    &:before {
      top: 15px;
      right: -12px;
      transform: rotate(90deg);
    }
  }
}

.videos {
  &.inactive {
    opacity: 0.6;
  }

  .listHeading {
    margin-top: 40px;
  }

  .listItem {
    background: white;
    border-radius: 5px;
    margin-top: 4px;
    transition: ease 0.2s all;
    cursor: pointer;
    position: relative;
    margin-bottom: 6px;
    border-bottom: 1px solid #efefef;
    padding: 10px;
    padding-bottom: 14px;

    &:last-child {
      border-bottom: none;
    }

    @media screen and (max-width: 992px) {
      margin-bottom: 0;
      height: auto;
      background: white;
      border-radius: 2px;
      padding: 20px 30px 10px 30px;
      margin-top: 20px;
      hr {
        display: none;
      }
    }

    &:after {
      position: absolute;
      bottom: -15px;
      content: "";

      height: 1px;
      background: lighten($heavymetal, 70);
    }


    &:hover {
      box-shadow: 3px 4px 30px -8px rgba(0, 0, 0, 0.18);

      .img {
        &:before {
          background-color: rgba($color: #000000, $alpha: 0);
          background-size: 40px auto;

        }
      }
    }

    .img {
      width: 100%;
      height: 120px;
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      background-size: cover;

      @media screen and (max-width: 992px) {

      }

      &:before {
        transition: ease 0.2s all;
        position: absolute;
        content: "";
        background-image: url("/static/img/icon-play.png");
        background-repeat: no-repeat;
        background-size: 30px auto;
        background-position: center center;
        background-color: rgba($color: #000000, $alpha: .2);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

      img {
        object-fit: cover;
        width: 180px;
        height: 106px;

      }
    }

    h3 {
      line-height: 20px;
      margin: 0px;
      margin-top: 10px;
      padding-bottom: 3px;
      padding-top: 3px;
      white-space: nowrap;
      width: 100%;
      display: inline-block;
      position: relative;
      overflow-x: hidden;
      text-overflow: ellipsis;
      left: 0px;
      padding-right: 10px;
      position: relative;
      display: inline-block;

      &:hover {
        display: inline-block;
        width: auto;
      }
    }

    p {
      font-size: 0.7em;
      margin-top: 4px;
      padding-right: 5px;
      line-height: 1.3em;
      color: $heavymetal;

    }

    .progress-row {
      margin-top: auto;
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: 700;
      white-space: nowrap;
      @media screen and (max-width: 992px) {
        font-size: 0.7em;
        opacity: 0.6;
      }

      .progress {
        margin-top: 10px;
        @media screen and (max-width: 992px) {
          margin-top: 8px;
        }
      }
    }

    .progress-row {
      margin-top: auto;
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: 700;
      white-space: nowrap;
      @media screen and (max-width: 992px) {
        font-size: 0.7em;
        opacity: 0.6;
      }

      .progress {
        margin-top: 10px;
        @media screen and (max-width: 992px) {
          margin-top: 8px;
        }
      }
    }

    &.assessment {
      transition: ease 0.2s all;
      border-radius: 2px;;
      padding: 0 10px;

      .subject-list-link {
        display: flex;
        flex-direction: column;
        /* height: 120px; */
        min-height: 80px;
        justify-content: center;
      }

      h3 {
      }

      span {
        color: $aspargus;
        font-size: 14px;;
      }

      &:hover {
      }

      .icon {
        background: rgba($aspargus, 0.20);
        border: 1px solid rgba(32, 161, 107, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
        border-radius: 3px;
        margin: 0;
      }

      button {
        padding: 14px 0;
      }
    }
  }

  .flag {
    background: white;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 5px;
    top: 5px;

  }

  .flag img {
    width: 20px;
  }
}

.video-item {
  width: 100%;
}

.highlightCampaign {
  background-color: #343534;
  padding: 30px 10px;
  margin-bottom: 60px;

  h2 {
    color: #fff;
  }
}


.upload-zone {
  padding: 10px;
  background: darken($desertstorm, 5);
  display: inline-block;
  margin-top: 10px;
}

.content-white {
  border-radius: 5px;
  background: white;
  margin-top: 20px;
  padding: 40px;
}

.mobile-hide {
  @media screen and (max-width: 850px) {
    display: none;
  }
}

.simple-list {
  border-radius: 5px;
  background: white;
  margin-top: 20px;
  padding: 40px;

  .header {
    font-size: 0.7em;
    border-bottom: 1px solid $desertstorm;
    padding-bottom: 14px;

    .title_left_padding {
      padding-left: 50px;
    }

  }

  .col {
    white-space: nowrap;
    overflow: hidden;
  }

  .icon {
    float: left;
    margin-right: 10px;

    img {
      width: 20px;
    }
  }

  .text {
    color: darken($desertstorm, 30);
    font-size: 0.8em;
    font-weight: 100;
  }

  .line {
    border-bottom: 1px solid $desertstorm;
    padding: 2px 4px;
    line-height: 32px;
    font-size: 0.9em;

    .title {
      padding-left: 0;
    }

    a {
      color: $aspargus;
      text-decoration: underline;
    }

    .date {
      font-size: 0.8em;
    }
  }
}

.tabs-holder {
  background: transparent !important;
  padding: 0 !important;

  .tabs {
    min-width: 320px;
    max-width: 800px;
    margin: 0 auto;
  }

  .tab-content {
    display: none;
    padding: 40px;
    border-top: 1px solid #ddd;
    background: white;
    font-size: 16px;

    .editable {
      border: 2px dashed darken($desertstorm, 10);
      padding: 20px;
      border-radius: 5px;
    }

    a {
      color: $calypso;
      text-decoration: underline;

    }
  }

  input {
    display: none;
  }

  label {
    display: inline-block;
    margin: 0 0 -1px;
    padding: 15px 25px;
    font-weight: 600;
    text-align: center;
    color: #bbb;
    border: 1px solid transparent;
    background: white;
    margin-right: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  label:before {
    font-weight: normal;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 20px auto;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: "";
  }

  label[for*='1']:before {
    background-image: url(/static/img/icon-email1.png);

  }

  label[for*='2']:before {
    background-image: url(/static/img/icon-email2.png);
  }

  label[for*='3']:before {
    background-image: url(/static/img/icon-email3.png);
  }

  label[for*='4']:before {
    background-image: url(/static/img/icon-finger-click.png);
  }


  label:hover {
    color: #888;
    cursor: pointer;
  }

  input:checked + label {
    color: #555;
    border: 1px solid #ddd;
    border-top: 2px solid $aspargus;
    border-bottom: 1px solid #fff;
  }

  #tab1:checked ~ #content1,
  #tab2:checked ~ #content2,
  #tab3:checked ~ #content3,
  #tab4:checked ~ #content4 {
    display: block;
  }

  @media screen and (max-width: 650px) {
    label {
      font-size: 0;
    }
    label:before {
      margin: 0;
      font-size: 18px;
    }
  }

  @media screen and (max-width: 400px) {
    label {
      padding: 15px;
    }
  }
}

.big-column-list {
  .content-row {
    margin: 40px auto;
    padding: 20px 0;
    color: lighten($heavymetal, 20);
    border-bottom: 1px solid darken($desertstorm, 5);

    .leftMenu {
      text-align: center;

      .img {
        &.email {
          max-width: 80px;
        }
      }

      .img {
        margin: 10px auto;
        max-width: 120px;

        img {
          max-width: 100%;
        }
      }
    }

    .content-box {
      padding: 40px;
      background: white;
      border-radius: 10px;
    }
  }

  .content {
    background: white;
    padding: 40px;
  }

  a {
    color: $calypso;
    text-decoration: underline;
  }

  .link_to_copy {
    padding: 5px 15px;
    background: lighten($desertstorm, 5);
    border-radius: 4px;
  }
}

.subject {
  .header-subject {
    margin: 40px 0 40px 0;
    @media screen and (max-width: 992px) {
      margin: 80px 0 40px 0;
    }
  }

  h3 {
    margin-bottom: 0;
  }

  .time {
    font-size: 12px;
  }

  .video {
    padding: 0 !important;
  }

  h1 {
    margin-top: 0px;
    margin-bottom: 0;
    font-size: 2em;
  }

  p {
    margin-top: 20px;
    color: lighten($heavymetal, 15);
    font-size: 0.9em;
  }

  .dropdown-list {
    border-bottom: 1px solid darken($snowdrift, 5);
    margin-bottom: 35px;
    position: relative;
    display: flex;

    .current {
      z-index: 1000;
      float: left;
      padding: 10px 40px 10px 10px;
      border-bottom: 2px solid $shiraz;
      margin-right: 20px;
      position: relative;
      font-size: 0.9em;

      &:after {
        position: absolute;
        content: "";
        right: 0;
        width: 40px;
        height: 30px;
        background: url('/static/img/icon-arrow-down.png') no-repeat center center;
      }
    }

    .list {
      padding: 10px;
      font-size: 0.9em;
      margin-left: 20px;
      padding: 10px 40px 10px 10px;
      position: relative;
      cursor: pointer;

      &:after {
        position: absolute;
        content: "";
        right: 0;
        width: 40px;
        height: 30px;
        background: url('/static/img/icon-shield-small.png') no-repeat center top;
      }
    }

    .droplist {
      position: absolute;
      top: 50px;
      width: 100%;
      background: white;
      border-radius: 5px;
      border: 1px solid $snowdrift;
      z-index: 100;
      display: none;
      transition: ease 0.2s all;

      &.open {
        display: block;
      }

      .item {
        padding: 20px;
        position: relative;
        padding-left: 50px;
        border: 1px solid $desertstorm;
        border-radius: 4px;
        margin: 5px;

        &:before {
          position: absolute;
          content: "";
          left: 10px;
          width: 30px;
          height: 26px;
          background: $desertstorm;
          border-radius: 3px;
        }

        &:hover {
          background: $desertstorm;
        }
      }
    }
  }

  .video {
    background: white;
    border: 1px solid $snowdrift;
    padding: 20px;
    min-height: 500px;
    position: relative;
    @media screen and (max-width: 992px) {
      min-height: 200px;
    }

    .video-item {
      position: relative;
      z-index: 1;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: 80px auto;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #f9f9f9;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      border: 2px solid white;
      outline: 2px solid #f9f9f9;
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      background-image: url(/static/img/icon-loading.svg);
      background-size: 90px auto;
      background-position: center center;
      background-repeat: no-repeat;
      width: 90px;
      height: 90px;
      animation: loading 2s linear infinite;
      margin-left: -45px;
      margin-top: -45px;
      z-index: 0;
    }

    @media screen and (max-width: 992px) {
      padding: 0;
      margin: 0 -15px 0 -15px;
    }

    img {
      max-width: 100%;
    }
  }

  .description {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    color: lighten($heavymetal, 48);
    font-weight: 100;
  }

  .section-text {
    background: white;

    h2 {
      text-align: center;
      margin: 50px 0;
    }

    p {
      padding: 0;
      line-height: 1.5em;
    }
  }

  .section-exam {
    h2 {
      text-align: center;
      margin: 50px 0;
    }

    p {
      padding: 0 30px;
    }

    .exam-question-holder {
      background: lighten($snowdrift, 5);
      padding-bottom: 30px;
      background: #f9f9f9;
      padding-bottom: 30px;
      padding: 50px;
      margin: 20px 0 40px 0;
      border-radius: 2px;
    }

    .exam-question {
      padding: 0 0 10px 0;
      line-height: 2em;
      font-size: 1.1em;
    }

    .exam-question-counter {
      font-size: 0.7em;
      margin-top: 20px;
      opacity: 0.6;
    }

    .exam-answer {
      padding: 10px 30px;
      margin: 20px 40px;
      text-align: center;
      border: rgba($aspargus, 0.1);
      background: rgba($aspargus, 0.1);
      border-radius: 3px;
      transition: 0.2s ease all;
      font-size: 1em;
      color: $aspargus;
      font-weight: 700;
      @media (max-width: 992px) {
        margin: 20px 10px;

      }

      &.correct {
        background: $aspargus;
        color: white;
        position: relative;

        &:hover {
          background: $aspargus !important;
        }

        &:before {
          content: "";
          position: absolute;
          right: 16px;
          top: 10px;
          width: 20px;
          height: 20px;
          background-image: url("/static/img/icon-check-white.svg");
          background-repeat: no-repeat;
        }
      }

      &.incorrect {
        background: $shiraz;
        color: white;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          right: 12px;
          top: 15px;
          width: 20px;
          height: 20px;
          background-image: url("/static/img/icon-x-white.svg");
          background-repeat: no-repeat;

        }
      }


      &:hover {
        background: $aspargus;
        color: white;
        cursor: pointer;
      }
    }
  }

  .section-fact {
    padding: 150px 0;

    p {
      font-weight: 100;
      text-align: center;
    }
  }

  .section-next-subject {
    background: lighten($snowdrift, 6);
    padding: 150px 0;

    .pretitle {
      text-align: center;
    }

    h3 {
      margin: 20px 0 50px 0;
      text-align: center;
      text-transform: uppercase;
    }

    &:hover {
      box-shadow: 3px 4px 30px -8px rgba(0, 0, 0, 0.18);

      .img {
        &:before {
          background-color: rgba($color: #000000, $alpha: 0);
          background-size: 80px auto;
        }
      }
    }

    .img {
      max-width: 100%;
      width: 460px;
      margin: 0 auto;
      border-radius: 3px;
      overflow: hidden;
      margin-top: 16px;
      position: relative;

      &:before {
        transition: ease 0.2s all;
        position: absolute;
        content: "";
        background-image: url("/static/img/icon-play.png");
        background-repeat: no-repeat;
        background-size: 60px auto;
        background-position: center center;
        background-color: rgba($color: #000000, $alpha: .2);
        width: 100%;
        height: 100%;
      }

      img {
        object-fit: cover;
        width: 860px;
        height: auto;
        max-width: 100%;
      }
    }
  }

  .custom-note {
    .subject-optional-note {
      border: 1px dashed #f9f9f9;
      padding: 20px 10px 20px 20px;
      border-radius: 6px;;
      margin: 20px 0;
      background: white;
      background: #f9f9f9;

      p {
        color: darken($heavymetal, 20);
        font-size: 1em;
        padding: 5px 0;
        margin: 5px 0;
      }
    }
  }
}


.footer {
  background: $heavymetal;
  color: $snowdrift;
  margin-top: 160px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.react-tagsinput {
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput--focused {
  border-color: #a5d24a;
}

.react-tagsinput-tag {
  background-color: $shiraz;;
  border-radius: 2px;
  border: 1px solid $shiraz;;
  color: darken($shiraz, 10);;
  display: inline-block;
  color: white;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}

/* Payment */
.payment-holder {
  .header {
    padding: 20px;
    background: darken($snowdrift, 2);
    text-align: center;
    color: $aspargus;

    h2 {
      font-size: 2em;
      margin: 0;
    }
  }

  .payment-amount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    background: white;
    padding: 30px;

    .price-description {
      font-size: 0.8em;
    }

    .price {
      font-size: 1.2em;
      color: $aspargus;
    }
  }

  .payment-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .pay-back {
      align-self: flex-end;

    }
  }

}

.credit-card-holder {
  display: flex;
  justify-content: center;
  background: lighten($snowdrift, 6);
  border-radius: 5px;

  .rccs {
    display: flex;
  }

  .rccs__card {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  .rccs__demo__content {
    display: flex;
  }

  form {
    padding: 60px;

    div {
      padding: 5px;

      input {
        border-radius: 3px;
        border: 0;
        padding: 8px 10px;
        font-size: 0.9em;
        color: darken($desertstorm, 60);

        &[name="expiry"] {
          margin-right: 10px;
          width: 130px;
        }

        &[name="cvc"] {
          margin-right: 10px;
          width: 110px;
        }
      }

      div {
        font-size: 0.8em;
        opacity: 0.8;
      }

    }
  }
}

.emailModal {
  max-width: 600px;
}

.certificateModal {
  max-width: 600px;

}

.certificateModal .fields {
  display: flex;
  flex-direction: column;

}


.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: #efefef;
  padding: 40px;

  &.certificateModal {
    @media (max-width: 992px) {
      max-width: 92%;
      min-width: unset;
      padding: 20px 30px;
    }
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    min-width: 500px;
    color: $heavymetal;
    border-radius: 4px;
    box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.1);
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    outline: none;

    &.time {
      background: white;
      padding: 40px;

      p {
        font-size: 16px;;
      }
    }

    .title {
      font-size: 32px;
      margin-left: 30px;
      text-align: center;
      margin-top: -10px;
      margin-bottom: 10px;
      color: $heavymetal;
      @media (max-width: 992px) {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 28px;
      }
    }

    label {
      text-align: center;
      margin: 5px;
    }

    input[type="text"] {
      height: 50px;
      background: white;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      margin-top: 15px;
    }

    .checkfield {
      font-size: 14px;
      color: darken($desertstorm, 20);
    }

    .description {
      margin-top: 15px;
      font-size: 14px;
      text-align: center;
      color: darken($desertstorm, 20);
    }

    .certificate-cancel {
      background: none;
      border: none;
    }

    .certificate-button {
      text-align: center;
    }

    .certificate-send {
      border-radius: 22px;
      flex: 1;
      margin-top: 10px;
      background: $aspargus;
      line-height: 24px;
      font-size: 18px;
      font-weight: 700;
      color: white;
      padding-left: 0px;
      padding-right: 23px;
      min-width: 400px;
      margin: 10px auto 0 auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
      position: relative;
      overflow: visible;
      transition: ease 0.2s background;
      border: none;

      &:hover {
        background: lighten($aspargus, 10);

        &:after {
          background-size: 34px auto;
        }
      }

      @media (max-width: 992px) {
        max-width: 96%;
        min-width: unset;
        padding-left: 20px;
        padding-right: 60px;

      }

      &:after {
        content: "";
        transition: ease 0.2s background;

        position: absolute;
        background: url('/static/img/icon-send.svg') no-repeat center center;
        background-size: 32px auto;
        right: 14px;
        top: 4px;
        height: 44px;
        width: 44px;

      }
    }

  }


  &.email {
    max-width: 600px;
    background-color: $snowdrift;
    margin: 0 auto;
  }

  .video-item {
    width: 100%;
    margin: 0 auto;
    max-width: 1000px;
  }

  h2 {
    color: $heavymetal;
  }

  .video {
    text-align: center;
    display: flex;
  }
}

.ReactModal__Overlay {
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($heavymetal, 0.88) !important;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rebeccapurple;
}

.modalClose {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $snowdrift;
  border-color: $snowdrift;
  box-shadow: 0px 4px 14px rgba(29, 30, 29, 0.04);

  img {
    width: 80%;
    height: auto;
  }

  &:focus {
    outline: none;
  }
;
}

.modalCertificateRibbon {
  position: absolute;
  top: -20px;
  left: 64px;

  svg {
    width: 40px;
  }
}

.loading-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: ease 0.1s all;
  background: rgba($heavymetal, 0.9);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-content: center;

  .loading {
    background: none;
    justify-content: center;
  }

}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 5px 15px 5px;
  border-radius: 5px;
  color: white;

  &__msg {
    font-size: 16px;
    font-weight: 100;
    @media (max-width: 992px) {
      text-align: center;
    }
  }

  &__dots {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin: 30px 0 30px 0;

    &__dot {
      background-color: rgba($aspargus, 0.8);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 5px;
      color: #587B7F;

      &:nth-child(1) {
        animation: bounce 1s 1s infinite;
      }

      &:nth-child(2) {
        animation: bounce 1s 1.2s infinite;
      }


      &:nth-child(3) {
        animation: bounce 1s 1.4s infinite;
      }
    }
  }
}


.time-counter {
  width: 100%;
  position: relative;
  color: darken($desertstorm, 20);
  text-align: right;
  padding-right: 44px;
  margin-top: 20px;

  span {
    color: $desertstorm;

  }

  &:after {
    position: absolute;
    content: "";
    right: 0px;
    top: -6px;
    width: 40px;
    height: 40px;
    background: url('/static/img/icon-clock.svg') no-repeat center center;
    background-size: 30px auto;
  }

  .right {
    text-align: right;
  }

  .left {
    text-align: left;
  }
}


.tip {
  width: 100%;
  position: relative;
  color: darken($desertstorm, 20);
  padding: 14px 20px 14px 48px;
  margin: 20px 0;
  background: white;
  font-weight: 100;
  border-radius: 5px;
  font-size: 0.9em;

  &:after {
    position: absolute;
    content: "";
    left: 4px;
    top: 4px;
    width: 40px;
    height: 40px;
    background: url('/static/img/icon-tip.svg') no-repeat center center;
    background-size: 30px auto;
  }

  .right {
    text-align: right;
  }

  .left {
    text-align: left;
  }

}

.wizard {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 1001;
  padding: 0;
  align-items: center;

  &.scorm {
    .wizard-content {
      @media (max-width: 992px) {
        padding: 0;
      }
    }
  }

  h1 {
    font-weight: 700;
    font-size: 1em;
    margin: 10px 0;
    // max-width: 76%;
    text-align: center;
    @media (min-width: 992px) {
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
  }


  .btn {
    padding: 0.7rem 2.4rem;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.04em;
    transition: 0.2s ease all;

    &.btn-outline-success {
      border: 1px solid $aspargus;
      color: $aspargus;

      &:hover {
        color: white;
      }
    }

    &.btn-secondary {
      color: $aspargus;

      &:hover {
        color: white;
      }
    }

    &.btn-outline-secondary {
      color: lighten($heavymetal, 20);
      border: 1px solid lighten($heavymetal, 40);
      background: none;

      &:hover {
        color: lighten($heavymetal, 10);
        border: 1px solid lighten($heavymetal, 10);
        background: lighten($heavymetal, 76);
      }
    }
  }


}


.wizard-header {
  background: white;
  height: 70px;
  box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.08);
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
  max-width: 1560px;
  width: 100%;

  .wizard-mobile-header {
    display: none;

    @media (max-width: 992px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 60px;

      h1.started {
        margin: 0;
        text-align: left;
        padding-top: 5px;
        font-size: 14px;
      }
    }
  }

  .wizard-mobile-menu {
    @media (min-width: 992px) {
      display: none !important;
    }
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 22px;
    background-image: url('/static/img/icon-menu.svg');
    background-repeat: no-repeat;
    display: none;

    &.close {
      top: 30px;
      background-image: url('/static/img/icon-arrow-down.png');
    }

    &.show {
      display: block;
    }
  }

  .wizard-close {
    background: white;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 20px;
    top: 16px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .wizard-header-status {
    background: white;
    position: absolute;
    right: 20px;
    top: 10px;
    bottom: 5px;
    padding: 3px;
    padding-left: 0;
    font-size: 12px;

    @media (max-width: 992px) {
      display: flex;
      padding-bottom: 5px;
      position: static;
      width: 70%;
      justify-content: flex-start;

      span {
        color: rgba($heavymetal, 0.6)
      }
    }
  }

  .wizard-header-score {
    background: white;
    position: absolute;
    right: 10px;
    top: 5px;
    bottom: 5px;
    padding: 3px;
    font-size: 14px;
    width: 300px;
    display: flex;
    line-height: 50px;

    span {
      margin-right: 4px;;
    }

    .progress-semicircle {
      width: 50px;

      .text {
        .score {
          font-size: 13px;
        }
      }

    }
  }

  .wizard-header-answers {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 12px;
      line-height: 16px;
      color: rgba($heavymetal, 0.4)
    }

    div {
      font-size: 18px;
      line-height: 24px;
      font-weight: 800;
    }
  }
}

.wizard-header-progress {
  max-width: 1560px;
  width: 100%;
}

.wizard-score {
  position: absolute;
  right: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: 11px;
  opacity: 0.8;
  @media (max-width: 992px) {
    text-align: center;
    position: relative;
    align-items: center;
    right: unset;
    span {
      strong {
        font-size: 1.2em;
        color: $heavymetal;
      }
    }
  }
}

.wizard-progress {
  height: 5px;
  border-radius: 0;
}

.wizard-body {
  display: flex;
  height: 100%;
  overflow: hidden;
  background: white;
  box-shadow: 12px 0 15px -4px rgba(0, 0, 0, 0.03), -12px 0 8px -4px rgba(0, 0, 0, 0.03);
  max-width: 1560px;
  width: 100%;

  &.scroll-y {
    overflow-y: scroll;
  }

  &.scroll {
    overflow: scroll;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @media (max-width: 992px) {
    overflow: scroll;
  }

  .wizard-body-header {
    display: flex;
    max-width: 910px;
    justify-content: space-between;

    h3 {
      margin: 40px 0 4px 0;
    }

    .action {
      display: flex;
      align-items: center;
      margin-left: 30px;

    }
  }
}

.wizard-content {
  .container {
    height: 100%;
    padding-right: 0;
    padding-left: 0;

    .home {
      height: 100%;

      .row {
        height: 100%;
      }
    }

    @media (max-width: 1120px) {
      padding: 0 20px;
      margin: 0 20px;
      max-width: 1100px;
    }
    @media (max-width: 992px) {
      max-width: 890px;
      padding: 0 10px;
      margin: 0 0px;
    }
  }

  @media (max-width: 992px) {
    padding: 0px;
  }

  &.full-width {
    max-width: 100%;
  }

  .form-email {
    font-weight: 600;
    font-size: 16px;
    margin: 10px 0;
  }

  h3 {
    font-family: Nunito Sans;
    margin: 20px 0 10px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
  }

  p {
    color: lighten($heavymetal, 5);
    font-size: 18px;
    line-height: 1.6em;
  }

  .actions {
    button {
      margin: 0;
      margin-right: 10px;
    }
  }


  label {

  }

  small {
    margin: 10px 0 5px 0;
    font-size: 14px;
    line-height: 1.4em;
    opacity: 0.6;
  }

  flex: 2;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;

  .dragZone {
    background: white !important;

    .dragLeft {
      background: #fefefe;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;

      img {
        transition: ease 0.2s all;
        opacity: 0;
      }
    }

    .remove-subject {
      background: #fefefe;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      display: flex;
      min-width: 40px;
      width: 40px;
      align-items: center;
      justify-content: center;

      img {
        padding: 5px;
        width: 25px;
        cursor: pointer;
        transition: ease 0.2s all;
        opacity: 0;
      }
    }

    .dragItem {
      border-radius: 2px;
      transition: ease 0.2s all;
      margin-right: 50px;

      &:hover,
      &:active {
        box-shadow: 0px 4px 14px rgba(29, 30, 29, 0.04);

        .dragLeft {
          img {
            opacity: 1 !important;
          }
        }

        .remove-subject {
          img {
            opacity: 1 !important;
          }
        }
      }
    }
  }

  select, .bigInput {
    background: rgba(240, 239, 239, 0.2);
    border: 1px solid #F0EFEF;
    box-sizing: border-box;
    font-size: 14px;
    height: 40px;
    border-radius: 4px;

    &:focus {
      border: 1px solid rgba(108, 157, 85, 0.5);
      outline: none;
    }
  }

  textarea.bigInput {
    height: auto;
  }

  // lists
  .simple-list {
    font-size: 16px;

    .container-checkbox {
      font-size: 16px;
      font-weight: 700;


      .checkmark {
        width: 20px;
        height: 20px;
        border: 2px solid lighten($heavymetal, 20);
        border-radius: 2px;
        top: 6px;
        pointer-events: none;

      }

      .checkmark:after {
        border-width: 0 3px 3px 0 !important;
        border: 2px solid #676867;
        top: -1px;
        left: 5px;
        width: 7px;
        height: 15px;
      }

      input:checked ~ .checkmark {
        background: $aspargus;
        border-color: $aspargus;

        &:after {
          border-color: white !important;
        }
      }
    }
  }

}


.wizard-footer {
  background: white;
  height: 80px;
  box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.08);
  box-sizing: content-box;
  display: flex;
  align-items: center;
  z-index: 100;
  max-width: 1560px;
  width: 100%;

  .btn {
    margin: 0 20px;

    &.continue {
      margin-left: auto;

      &.full-width {
        width: 90%;
        margin: auto;
      }

      @media (max-width: 992px) {
        margin: 0 10px;
      }
    }

    @media (max-width: 992px) {
      margin: 0 10px;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    margin-left: 250px;
    @media (max-width: 992px) {
      margin-left: unset;

    }

    .mobile-arrow-back {
      @media (max-width: 992px) {
        padding: 0.7rem 0rem;
        width: 70px;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        background-image: url('/static/img/icon-arrow-back.svg');
        background-color: rgba($heavymetal, 0.2);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 14px auto;
      }
    }

    &.answers {
      width: 90%;
      max-width: 90%;
      margin: 0 auto;

      button {
        margin: unset;

      }
    }
  }

  .showMenu {
    @media (min-width: 992px) {
      display: none;
    }
    padding: 10px 20px;
    background-image: url('/static/img/icon-menu-mobile.svg');
    background-color: rgba($heavymetal, 0.2);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 14px auto;
    padding: 0.7rem 0rem;
    width: 70px;
  }
}

.blog {
  .image {
    height: 100px;
    border-radius: 2px;
    overflow: hidden;
    background-size: cover;
  }
}


.block {
  background: white;
  border-radius: 4px;
  padding: 20px 30px 20px 30px;
  margin-top: 20px;

  &.tab-list {
    padding: 0 0 1px 0;
    margin-bottom: 20px;

    .block-tabs {
      @media (max-width: 992px) {
        padding: 10px;
      }
      padding: 30px 30px 0px 30px;
      border-bottom: 1px solid rgba($heavymetal, 0.1);

      ul {
        display: flex;
        padding-inline-start: 0px;
        margin-bottom: 0;
        @media (max-width: 992px) {
          padding: 10px 0px;
          justify-content: center;
        }

        li {
          list-style: none;
          font-weight: 800;
          font-size: 20px;
          line-height: 27px;
          padding: 4px 0px 20px 0px;
          @media (max-width: 992px) {
            padding: 0px 0px 10px 0px;
            margin: 10px;
          }
          margin-left: 15px;
          margin-right: 30px;
          border-radius: 2px;

          &.active, &:hover, &:focus {
            border-bottom: 3px solid $shiraz;
          }
        }
      }

    }
  }

  &.desktop {
    @media (max-width: 992px) {
      background: transparent;
      padding: 0;

    }
  }

  &.mobile {
    @media (min-width: 992px) {
      background: transparent;

    }
  }

  &.subjects {
    @media (max-width: 992px) {
      background: transparent;
      padding: 0;
    }
  }

  &.wide {
    padding: 20px 100px 10px 90px;
    @media (max-width: 992px) {
      padding: none;
    }
  }

  &.no-top-padd {
    margin-top: 0px;
    box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.1);
  }

  &.email-sent {
    background: #fbfbfb;

    h2 {
      line-height: normal;
      font-size: 40px;

    }

    max-width: 540px;
    padding: 60px 60px;
    margin: 0 auto;

    p {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
}

.block-shadow {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08);
  border-radius: 6px;

}

.block-steps {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  min-width: 250px;
  padding: 20px 0 !important;
  height: fit-content;

  .step {
    position: relative;
    white-space: nowrap;
    line-height: 36px;
    padding-left: 30px;
    padding-right: 10px;
    font-size: 12px;
    text-transform: uppercase;
    color: lighten($heavymetal, 60);
    font-weight: 700;
    border-style: solid;
    border-left-width: 4px;
    border-color: white;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    letter-spacing: 0.04em;
    padding-right: 20px;

    &:after {
      position: absolute;
      content: "";
      width: 20px;
      height: 36px;
      right: 0px;
      top: 0px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 14px auto;
      transition: ease 0.2s all;
    }

    &.viewed {
      &:after {
        background-image: url('/static/img/icon-subject-inprogress.svg');
      }

      &.answered {
        &:after {
          background-image: url('/static/img/icon-subject-completed.svg');
        }
      }
    }

    &.quiz {
      &:after {
        background-image: url('/static/img/icon-quiz.svg');
      }
    }

    &.answered {
      &:after {
        background-image: url('/static/img/icon-subject-completed.svg');
      }
    }

    &.incorrect {
      &:after {
        background-image: url('/static/img/icon-subject-incorrect.svg');
      }
    }

    &:not(.enabled) {
      &:after {
        background-image: url('/static/img/icon-certificate-enabled.svg');
        background-repeat: no-repeat;
        background-size: 14px auto;
        top: 8px;
        background-image: url('/static/img/icon-clock2.svg');

      }
    }

    &.certificate {
      &:after {
        background-image: url('/static/img/icon-certificate2.svg');
        background-repeat: no-repeat;
        background-size: 14px auto;
        top: 0;

      }

    }


    &.selected {
      border-left: 4px solid $aspargus;
      color: $aspargus;
    }


    &.active {
      border-left: 4px solid $aspargus;

      a {
        color: $aspargus;
        font-weight: 800;

      }

      span {
        font-weight: 600;
      }
    }

    &.enabled {
      opacity: 1;
      cursor: pointer;
      color: $heavymetal;

      &:hover {
        color: lighten($aspargus, 10);
        border-left: 4px solid lighten($heavymetal, 40);
      }

      &:after {
        position: absolute;
        content: "";
        width: 20px;
        height: 36px;
        right: 0px;
        top: 0px;
        background-size: 14px auto;
      }

    }

    .step-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .trainings-count, .step-count {
      padding: 0 10px;
      text-transform: initial;
      font-size: 12px;
      text-align: right;
    }
  }
}

.completed-banner {

}

.details {
  font-size: 12px;
  color: $bitter;
}

body .jwplayer.jw-state-error .jw-title, .jw-state-idle .jw-title, .jwplayer.jw-state-complete:not(.jw-flag-casting):not(.jw-flag-audio-player):not(.jw-flag-overlay-open-related) .jw-title {
  display: none !important;
}


body .jwplayer.jw-state-error .jw-title, .jw-state-idle .jw-title, .jwplayer.jw-state-complete:not(.jw-flag-casting):not(.jw-flag-audio-player):not(.jw-flag-overlay-open-related) .jw-title {
  display: none !important;
}

.quizHidden-holder {
  position: relative;
  height: 200px;
  pointer-events: none;
  margin-top: 20px;

  .quizOverlay {
    background: rgba($snowdrift, 0.7);
    display: flex;
    flex-direction: row;
    color: $heavymetal;
    border-radius: 2px;
    z-index: 2;
    position: absolute;
    width: 100%;
    background: linear-gradient(180deg, rgba(white, 0) 0%, rgba(white, 0.8) 100%);
    height: 300px;
    padding: 0px 20px;
    justify-content: center;

    .title {
      font-size: 1.1em;
      font-weight: 600;
      line-height: 21px;
    }

    p {
      margin: 0;
    }

    .image {
      width: 50px;

      img {
        width: 100%;
      }
    }

    .quizHiddenText {
      text-align: left;
      margin-left: 14px;
    }

  }

  .quizHidden {
    opacity: 0.1;;
    position: absolute;
    display: flex;
    z-index: 1;
    flex-direction: column;
  }

}

.sidebar {
  overflow-y: auto;
  margin-right: 10px;
  transition: ease 0.2s all;
  @media (max-width: 992px) {
    position: fixed;
    top: 100vh;
  }

  h1 {
    padding: 0 0 20px 0;
  }

  h4 {
    line-height: 1.5em;
  }

  .block {
    box-shadow: none;
  }

  &.open {
    top: 0;
    z-index: 1000;
    margin-top: 61px;
    padding-bottom: 30px;
    width: 100%;
    background: white;
    overflow-y: scroll;
    height: 100%;

    .block {
      margin-bottom: 110px;
    }
  }

  .btn-fixed {
    @media (min-width: 992px) {
      display: none;
    }
    position: fixed;
    height: 60px;
    z-index: 1001;
    width: 100%;
    background: white;
    bottom: 0;
    text-align: center;
    padding: 10px;
    padding: 0.7rem 0rem;
    box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.31);

    .btn {
      background-color: rgba($heavymetal, 0.2);
      color: $heavymetal;

    }
  }

  .news-item {
    h4, p {
      transition: ease 0.2s all;
    }

    p {
      font-size: 12px;
      line-height: 16px;
      color: #1D1E1D;
    }

    &:hover {
      h4 {
        color: black;
        text-decoration: underline;
      }

      p {
        opacity: 0.8;;
      }
    }
  }

}

.subject-list-link {
  display: flex;
  flex-direction: column;
  height: 120px;
}

.subject-list-description {
  max-height: 2.9em; /* line-height × 3 */
  overflow: hidden;
}

.subject-list-description > p {
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 1.3em;
}

.subject-list-description > p::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(100% - 3.9em); /* 100% - (line-height × 3) */
  transform: translateY(-1.3em); /* 0 - line-height */
  background: repeating-linear-gradient(rgba(255, 255, 255, 0), #ffffff 1.3em); /* line-height */
}


.snackbar {
  position: fixed;
  display: flex;
  z-index: 1000;
  background: $snowdrift;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  div[color="success"] {
    border-top: 4px solid $aspargus;
  }

  bottom: 40px;
  left: 0;
}

.snackbar-center-top {
  top: 100px;
  left: 0;
  bottom: initial;
}

.snackbar-left-bottom {
  border-radius: 4px;
  bottom: 40px;
  left: 0;
  align-items: flex-start;
  justify-content: center;

}

.snackbar-content {
  z-index: 100;
  line-height: 40px;
  font-size: 14px;
  transition: ease 0.4s all;

  &.alert-success {
    border-top: 4px solid $aspargus;
    color: darken($aspargus, 20);
    background-color: $snowdrift;
  }
}


.helmet {
  padding: 40px 6px 10px 6px;

  h2 {
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: rgba($heavymetal, 0.9)
  }
}

// footer stays on the bottom of the page, below container

.layout_wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container-wrapper {
  flex-grow: 1;
}

footer {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
  color: rgb(153, 153, 153);
  font-size: 0.7em;

  a {
    &:hover {
      text-decoration: underline;
      color: rgb(153, 153, 153);
    }
  }
}

.sentry-error-embed-wrapper {
  z-index: 2147483647;
}

.powered-by {
  display: none !important;
}
