.reactable-page-button {
  margin-left: 5px;
  margin-right: 2px;
  margin-bottom: 6px;
  padding: 3px;
  width: 20px;
  float: left;
  cursor: pointer;
  color: $heavymetal;
  background-color: none;
  text-align: center;
  border-radius: 5px;
  font-size: 0.8em;

  &:hover {
    background-color: $desertstorm;
  }
}

.reactable-next-page, .reactable-previous-page {
  font-size: 0.8em;
  margin-left: 5px;
  color: $calypso;
}

.reactable-current-page {
  color: $aspargus;
  cursor: default;
}

#demo-table th {
  cursor: pointer;
}

th.reactable-header-sort-desc, th.reactable-header-sort-asc {
  background-color: white;
}

th.reactable-header-sort-desc:after {
  content: "";
  width: 8px;
  margin-left: 10px;
  height: 8px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  border: solid $aspargus;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  font-size: 10px;
}

th.reactable-header-sort-asc:after {
  content: "";
  width: 8px;
  margin-left: 10px;
  height: 8px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border: solid $aspargus;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  font-size: 10px;

}

tr.reactable-filterer {
  width: 100%;
}

tr.reactable-filterer td {
  width: 100%;
}

.reactable-filter-input {
  border-radius: 4px;
  border: 2px solid #ccc;
  padding: 3px 6px;
  margin-left: 10px;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-right: 20px;
  outline: 0;

  &:focus {
    border: 2px solid $aspargus;
    outline: 0;
  }
}
