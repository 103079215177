@import "../../../node_modules/bootstrap/scss/functions.scss";
@import "../../css/variables";

.modal {
  position: relative;
  top: 0px;
  left: 40px;
  right: 40px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 230px;
  padding: 20px 48px;

  @media (max-width: 992px) {
    left: 0px;
  }


  &.slim {
    width: 680px;
    display: flex;
    justify-content: center;

    @media (max-width: 992px) {
      width: 90%;
    }
  }

  h3 {
    color: $heavymetal;
    font-size: 24px;
    font-weight: 700;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
  }

  .modalContent {
    font-size: 13px;
    font-weight: 400;
  }

  .modalFooter {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 15px 0;
  }
}

.modal-close-right {
  cursor: pointer;
}

.green-text {
  color: $aspargus;
}
