.progress {
  border-radius: 8px;
  background-color: #F0EFEF;
  height: 0.5rem;

  &.progress-bar-medium {
    border-radius: 4px;
    height: 8px;
    margin-top: 5px;
  }

  &.progress-bar-slim {
    border-radius: 3px;
    height: 4px;
    padding: 0;
  }
}

.progress-counter-right {
  font-size: 16px;
  font-weight: 600;

  &.warning {
    color: $orange;
  }

  &.error {
    color: $shiraz;
  }

  &.success {
    color: $aspargus;
  }
}

.progress-with-number {
  display: flex;
  align-items: center;
}
